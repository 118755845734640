import { Button } from "@mui/material"
import { Link, Outlet, useNavigate } from "react-router-dom"

export const DocumentLayout = () => {
    const navigate = useNavigate();

    return (
        <div>
           
            <Outlet />
        </div>
    )
}