import { Backdrop, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Card from '@mui/material/Card';
// import Typography from "src/theme/overrides/Typography";
import Swal from "sweetalert2";
import styles from "../styles/user.module.scss";
import ErrorMessages from "src/Core/ErrorMessages";


const UpdateUser = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [userName, setUserName] = useState("");
    const [userType, setUserType] = useState("");
    const [loading, setLoading] = useState(false);
    const [editUserErrorMessage, setEditUserErrorMessages] = useState(false);


    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };



    const allUsers = async () => {

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${params.id}`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                }
            }

            let res = await fetch(url, options);
            let responseData = await res.json();

            setFirstName(responseData.data["first_name"]);
            setLastName(responseData.data["last_name"]);
            setMobile(responseData.data["mobile"]);
            setUserName(responseData.data["username"]);
            setUserType(responseData.data["user_type"]);
            setEmail(responseData.data["email"]);


        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }


    const editUser = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${params.id}`;
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    mobile: mobile,
                    email: email,
                    username: userName,
                    user_type: userType,
                }),
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'User Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    didClose: () => {
                        navigate("/dashboard/users")
                    }
                })

            }
            if (responseData.success === false) {
                setEditUserErrorMessages(responseData.error.issues)

            }
            if (responseData.status === 401) {
                handleLogout();
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        allUsers()
    }, [])

    return (
        <Container maxWidth="xl" id={styles.addUser}>

            <div className={styles.container}>
                <div>
                    <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Update Users
                    </Typography>
                </div>
                <Card variant="outlined" className={styles.addUserCard}>
                    <div className={styles.containerBlock}  >

                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="First Name"
                                    label="First Name"
                                    type={"text"}
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="first_name" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Last Name"
                                    label="Last Name"
                                    type={"text"}
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="last_name" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Email"
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="email" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Phone"
                                    label="Mobile Number"
                                    type="number"
                                    value={mobile}
                                    onChange={(e) => {
                                        setMobile(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="mobile" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="User Name"
                                    label="User Name"
                                    type="text"
                                    value={userName}
                                    onChange={(e) => {
                                        setUserName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="username" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <FormControl fullWidth sx={{

                                    '& .MuiInputBase-root': {
                                        minHeight: "47px",
                                        maxHeight: "47px",
                                    }
                                }}>
                                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={userType}
                                        label="User Type"
                                        onChange={(e) => {
                                            setUserType(e.target.value)
                                        }}
                                    >
                                        <MenuItem value="ADMIN">ADMIN</MenuItem>
                                        <MenuItem value="VIEWER">VIEWER</MenuItem>
                                        <MenuItem value="OPERATOR">OPERATOR</MenuItem>
                                    </Select>
                                </FormControl>
                                <ErrorMessages errormessage={editUserErrorMessage} pathname="user_type" />
                            </Grid>
                        </Grid>


                    </div>
                    <div style={{ display: "flex", "column-gap": "1em", marginTop: "2.5rem" }}>

                        <Button className={styles.cancelBtn} type="submit" sx={{ width: "40%", color: "#000000", background: "#ffffff", fontSize: "1.1rem", fontWeight: "500", border: "none" }} onClick={() => navigate('/dashboard/users')} variant="outlined">Cancel</Button>


                        <Button className={styles.submitBtn} type="submit" sx={{ width: "60%", fontSize: "1.1rem", fontWeight: "500", background: "#00a651" }} onClick={editUser} variant="contained">Update</Button>
                    </div>
                </Card>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </Container>
    )
}
export default UpdateUser;