import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import VideoPage from './pages/VideoPage';
import DocumentsPage from "./pages/DocumentsPage";
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AddVideoPage from './pages/AddVideoPage'
import AddDocumentPage from './pages/AddDocumentPage'


import EditVideoPage from './pages/EditVideoPage'
import EditDocumentPage from './pages/EditDocumentPage'

import Uppytest from './pages/uppytest'
import WatchVideos from './pages/WatchVideos'
import PlayVideo from './pages/PlayVideo'
import Folders from './pages/Folders';
import SingelFolderFiles from './pages/SingelFolderFiles';
import ImportVideoForm from './components/svg-color/ImportVideoForm';
import { DocumentLayout } from './pages/DocumentLayout';
import SignIn from './Auth/SignIn';
import AllUsers from 'src/AdminUsers/AllUsers';
import AddUser from 'src/AdminUsers/AddUser';
import UpdateUser from 'src/AdminUsers/UpdateUser';
import ViewUser from 'src/AdminUsers/ViewUser';
import ViewProfile from 'src/Auth/ViewProfile';
import EditViewProfile from './Auth/EditViewProfile';
import UpdetePassword from 'src/Auth/UpdatePassword';
import ImportDocumentForm from 'src/components/svg-color/ImportDocumentForm';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'videos', element: <VideoPage /> },
        { path: 'videos/add', element: <AddVideoPage /> },

        {
          path: 'documents',
          element: <DocumentLayout />,
          children: [
            { path: '', element: <DocumentsPage /> },
            { path: 'add', element: <AddDocumentPage /> },
            { path: ':id/edit', element: <EditDocumentPage /> },
            { path: 'watch-folders', element: <Folders /> },
            { path: 'watch-folders/:folders', element: <SingelFolderFiles /> },
          ]
        },

        { path: 'watch-videos', element: <WatchVideos /> },
        { path: 'videos/:id/edit', element: <EditVideoPage /> },
        { path: 'watch-videos/:id/play', element: <PlayVideo /> },
        { path: 'videos/upload-file', element: <ImportVideoForm /> },
        { path: 'users', element: <AllUsers /> },
        { path: 'users/add', element: <AddUser /> },
        { path: 'users/:id/edit', element: <UpdateUser /> },
        { path: 'users/:id/view', element: <ViewUser /> },
        { path: 'view-profile', element: <ViewProfile /> },
        { path: 'edit-profile', element: <EditViewProfile /> },
        { path: 'update-password', element: <UpdetePassword /> },
        { path: 'document/upload-file', element: <ImportDocumentForm /> },
      ],
    },
    { path: 'uppytest', element: <Uppytest /> },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'signin',
      element: <SignIn />,
    },
    {
      path: '',
      element: <Navigate to="/login" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    {
      path: '/products',
      element: <ProductsPage />,
    },
  ]);

  return routes;
}
