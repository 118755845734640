import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress, Chip, TablePagination } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { VideoCard, BlogPostsSort } from '../sections/@dashboard/blog';
import { useState, useEffect } from 'react';
//styles scss
import styles from "../styles/watchVideo.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
// components

// import stylesSearch from "../../../styles/watchVideo.module.scss";
import MobileMenu from 'src/components/MobileMenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchByTags from 'src/Core/SearchByTags';



// mock
// ----------------------------------------------------------------------
const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------


const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function WatchVideos() {
  const signinSliceData = useSelector((state) => state.Signin.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [videos, setVideos] = useState([])
  const [loading, setLoading] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12)
  const [tagsOptions, setTagsOptions] = useState([])
  const [tags, setTags] = useState([])
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    fetchVideos(page, limit);
    getAllTagsData();
  }, [])
  const handleLogout = async () => {
    dispatch({
      type: 'SIGN_OUT',
      payload: {},
    });
    navigate('/');
  };


  const fetchVideos = async (page, limit, tags = "") => {
    try {
      setLoading(true)
      const options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          authorization: `${signinSliceData.access_token}`,
        },
      }

      let url = `${process.env.REACT_APP_LOCAL_API_URL}/videos?page=${page + 1}&limit=${limit}`

      if (tags && tags.length) {
        url += '&tags=' + tags.join(",")
      }


      // if (url.indexOf('?') == -1) {
      //   url += '?get_all=true'
      // }
      // else {
      //   url += '&get_all=true'
      // }

      let response = await fetch(url, options)

      if (response.status == 200) {
        let respData = await response.json()
        if (respData.success) {
          setVideos(respData.data.data)
          setTotalCount(respData.data.total)
          setTotalPages(respData?.data?.total_pages);
          setCurrentPage(respData?.data?.page);
        }
      } else {
        if (response.success) {
          setVideos([])
        }
        if (response.status === 401) {
          handleLogout();
        }
      }
    }
    catch (err) {
      console.log(err)
      setVideos([])
    }
    finally {
      setLoading(false)
    }
  }

  const onTagsUpdate = (tags) => {
    setSelectedTags(tags);
    fetchVideos(0, limit, tags)
  }

  useEffect(() => {
    fetchVideos(page, limit, selectedTags);
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchVideos(newPage, limit, selectedTags);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(+event.target.value);
    fetchVideos(0, +event.target.value, selectedTags);
  };

  const fetchTagsOptions = async (query) => {
    const options = {
      headers: {
        'Content-Type': 'applications/json'
      }
    }

    if (query) {

      let res = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/tags-of-videos?get_all=true`)
      if (res.status == 200) {
        let responseData = await res.json()
        setTagsOptions(responseData.data.map((e) => e.tag))

      } else {
        let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/tags-of-videos?search_string=` + query, options)
        if (response.status == 200) {
          let respData = await response.json()
          setTagsOptions(respData.data.map((e) => e.tag))
        }
      }
    }
  }

  const fetchTagsBySearch = async (e) => {
    let query = e.target.value
    if (query.length) {
      await fetchTagsOptions(query)
    }
  }
  const fetchTagsOption = async (query) => {
    const options = {
      headers: {
        'Content-Type': 'applications/json'
      }
    }

    let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/tags-of-videos?get_all=true`)
    if (response.status == 200) {
      let respData = await response.json()
      setTagsOptions(respData.data.map((e) => e.tag))
    }
  }

  useEffect(() => {
    fetchTagsOption()
  }, [])



  const getAllTagsData = async () => {
    setLoading(true)

    const options = {
      headers: {
        "Content-Type": "application/json",
        authorization: `${signinSliceData.access_token}`,
      },
    }
    try {
      let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/tags-of-videos?get_all=true`, options)
      if (response.status == 200) {
        setLoading(false)

        let respData = await response.json()
        setAllTags(respData.data)
      }
    } catch (err) {
    }
  }
  const selectTagChips = (clickedtag) => {

    let finalTags = selectedTags;
    let isSelected = finalTags.findIndex((e) => e == clickedtag)
    if (isSelected == -1) {
      finalTags.push(clickedtag);
    } else {
      let deletedTagList = finalTags.filter((e) => e != clickedtag);
      finalTags = deletedTagList
    }
    setSelectedTags(finalTags);
    fetchVideos(page, limit, finalTags);
  }
  return (
    <>
      <Helmet>
        <title> Dashboard: Watch Videos | Video CMS </title>
      </Helmet>

      <Container className={styles.videoGrid}>
        <div className={styles.mobileSearch} >
          <MobileMenu />
          <Stack width={'100%'} mb={1} direction="row" alignItems="center" justifyContent="center" sx={{
            '& .MuiFormControl-root': {
              borderRadius: "50px",
            },
            '& .MuiInputBase-root': {
              borderRadius: "50px",

            },

          }}>
            <SearchByTags onTagsUpdate={onTagsUpdate} updatedTags={tags} tagsOptions={tagsOptions} fetchTagsBySearch={fetchTagsBySearch} />
          </Stack>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} className={styles.webHeader}>
          <Typography variant="h4" gutterBottom>
            Watch Videos
          </Typography>

          <Stack width={350} mb={1} direction="row" alignItems="center" justifyContent="center">
            <SearchByTags onTagsUpdate={onTagsUpdate} updatedTags={tags} tagsOptions={tagsOptions} fetchTagsBySearch={fetchTagsBySearch} />
          </Stack>

          {tagsOpen ?
            <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
              Hide Tags<KeyboardArrowDownIcon />
            </Button> :
            <Button style={{ color: '#ff0000', background: "#fdedb3" }} onClick={() => setTagsOpen(!tagsOpen)}>
              Show All Tags<KeyboardArrowRightIcon />
            </Button>
          }

        </Stack>
        {tagsOpen ?
          <div className={styles.showTagsContainer} >
            <div style={{ height: "100px", overflowY: "scroll" }}>
              {allTags && allTags.map((item, index) => {
                let isSelected = selectedTags.findIndex((e) => e == item.tag)
                if (isSelected == -1) {
                  return (
                    <span key={item.id} style={{ padding: "2px" }}>
                      <Chip className={styles.showTags} label={item.tag} clickable onClick={() => selectTagChips(item.tag)} />
                    </span>
                  )
                }
                else {
                  return (
                    <Chip className={styles.slectedTag} key={item.id} label={item.tag} clickable onClick={() => selectTagChips(item.tag)} />
                  )
                }
              })}
            </div>
          </div> : ""}
        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <WatchVideoSearch onTagsUpdate={fetchVideosByTags} />
        </Stack> */}

        <Grid container spacing={{ xs: 1, md: 3 }} sx={{ marginTop: "5px !important" }}>
          {videos.length ? videos.map((post, index) => (
            <VideoCard key={post.id} post={post} index={index} />
          )) : !loading ? <img src='/assets/no-data.png' width='150px' height='200px' style={{ margin: " 2rem auto" }} />
            : ""}
        </Grid>
        <div className={styles.pagination}>
          <Stack spacing={2} sx={{

            '& .Mui-selected': {
              backgroundColor: "#ffcb05 !important",
            },
            '& .MuiButtonBase-root': {
              fontWeight: "900",
            },
          }}>
            {!loading ? <TablePagination
              className={styles.tablePagination}
              rowsPerPageOptions={[12, 36, 60, 84, 108]}
              component="div"
              count={totalCount}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} videos / Page ${currentPage} of ${totalPages}`}
            /> : ""}
          </Stack>
        </div>
      </Container >

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </>
  );
}

