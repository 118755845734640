import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent, Stack, Button, Backdrop, CircularProgress, LinearProgress } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useVideoJs } from './useVideo'
// This imports the functional component from the previous sample.
import VideoJS from './VideoJs'
import videojs from 'video.js';
import { Stream } from "@cloudflare/stream-react";

import DownloadIcon from '@mui/icons-material/Download';
//styles scss
// import style from "../../../styles/watchVideo.module.scss";
import styles from "../../../styles/videoPage.module.scss";

import MustDownloadDailog from './mustDownloadDailog'
import { getThumbnailURL, getYoutubeEmbededURL } from 'src/utils/files';
// ------------------
PlayVideoCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};



export default function PlayVideoCard({ post, index }) {


  let { cover, title, view, comment, share, author, createdAt, tags, video_id, download_ready_status, download_ready_percetage, id, video_url } = post;


  const [url, setURL] = useState("")
  const [showDownloadConfirmation, setShowDownloadConfirmation] = useState(false)
  const [poster, setPoster] = useState("")
  const [downloadReadyStatus, setDownloadReadyStatus] = useState("")
  const [downloadURL, setDownloadURL] = useState("")

  const [loading, setLoading] = useState(false);

  let dowloadLoopInterval

  const checkUserNeedToDownloadVideo = () => {
    setShowDownloadConfirmation(true)

  }

  const fetchVidoeDownloadStatus = async (loading = true) => {
    try {

      if (loading) {
        setLoading(true)
      }
      const options = {
        method: 'GET',
      }

      let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/videos/` + id + `/download-url`,
        options)

      if (response.status == 200) {
        let respData = await response.json()

        if (respData.data.status == "ready") {
          setDownloadURL(respData.data.url)
          setDownloadReadyStatus("ready")
          clearInterval(dowloadLoopInterval)
        }
        else {
          setDownloadURL(respData.data.url)
          setDownloadReadyStatus("inprogress")

          startLoopDownloadStatus()
        }

      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false)
    }
  }


  const startLoopDownloadStatus = () => {
    dowloadLoopInterval = setInterval(() => {
      fetchVidoeDownloadStatus(false)
    }, 10000)
  }


  useLayoutEffect(() => () => {
    clearInterval(dowloadLoopInterval)
  }, [])

  const getPoster = () => {
    if (video_url) {
      return getThumbnailURL(video_url)
    }
    else {
      return `${process.env.REACT_APP_STREAM_BASE_URL}/${video_id}/thumbnails/thumbnail.jpg?time=8s&height=270`
    }
  }

  const getSources = () => {
    if (video_url) {
      return [{ src: `${process.env.REACT_APP_STREAM_BASE_URL}/${video_id}/manifest/video.m3u8` }]
    }

    return [{ src: `${process.env.REACT_APP_STREAM_BASE_URL}/${video_id}/manifest/video.m3u8` }]
  }




  const latestPostLarge = true;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [
    { number: comment, icon: 'eva:message-circle-fill' },
    { number: view, icon: 'eva:eye-fill' },
    { number: share, icon: 'eva:share-fill' },
  ];


  useEffect(() => {


  }, [])


  const makeSlug = (name) => {
    //converting name to lower case then spaces replacing with '-'
    //removing other special chars
    return name
      .trim()
      .toLowerCase()
      .replace(/ /g, "-") // replacing spaces with -
      .replace(/[^\w-]+/g, "") // removing special chars
      .replace(/(\-)\1+/gi, (str, match) => {
        // removing duplicate consecutive '-'
        return match[0];
      });
  };



  const VideoJsPlayer3 = () => {

    const videoRef = useRef(null);

    const pauseVideo = () => {
      videoRef.current.pause();
    };

    const durationChangeEvent = () => {
      let whereYouAt = videoRef.current.currentTime;
      if (whereYouAt > 59) {
        pauseVideo()
        checkUserNeedToDownloadVideo()
      }
    }


    let videoIdOrSignedUrl = `${process.env.REACT_APP_STREAM_BASE_URL}/${video_id}/iframe`

    return (
      <div  >
        <Card className={styles.playVideoCardOne}>

          <Stream onDurationChange={durationChangeEvent} onTimeupdate={durationChangeEvent}
            streamRef={videoRef}
            height={"550"}
            onPlaying={durationChangeEvent}
            onProgress={durationChangeEvent}
            onTimeUpdate={durationChangeEvent}
            controls src={videoIdOrSignedUrl} />
        </Card>
      </div >

    )
  }


  const YoutubePlayer = ({ url }) => {
    let actularUrl = getYoutubeEmbededURL(url)
    return (
      <div>
        <Card className={styles.playVideoCard} sx={{ position: 'relative' }}>
          <iframe height="550" width="100%"
            src={actularUrl}>
          </iframe>
        </Card>
        <Card className={styles.playVideoCardMobile} sx={{ position: 'relative' }}>
          <iframe height="225" width="100%"
            src={actularUrl}>
          </iframe>
        </Card>
      </div>
    )

  }


  const downloadVidoe = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadURL + `?filename=${makeSlug(title)}.mp4`;
    // the filename you want
    a.download = `${title}.mp4`;
    document.body.appendChild(a);
    a.click();
  }


  return (
    <Grid item xs={12} sm={latestPostLarge ? 24 : 6} md={latestPostLarge ? 12 : 3}>

      {video_id && <VideoJsPlayer3 height="550" width="100%"></VideoJsPlayer3>}

      {video_url && <YoutubePlayer url={video_url}></YoutubePlayer>}

      {
        downloadReadyStatus != "" &&
        <Stack>
          {
            downloadReadyStatus == 'inprogress' &&
            <div>
              <p> Generating Download Link In Progress </p>
              <LinearProgress />
            </div>
          }

          <Button
            onClick={downloadVidoe}
            disabled={downloadReadyStatus == 'inprogress'}
            endIcon={<DownloadIcon />}
          >Download
          </Button>
        </Stack>
      }



      {
        showDownloadConfirmation &&
        <MustDownloadDailog videoId={video_id}
          onClose={() => {
            setShowDownloadConfirmation(false)
          }}
          onDownload={() => {
            setShowDownloadConfirmation(false)
            fetchVidoeDownloadStatus()
          }}
        />
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid >
  );
}
