import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent, CircularProgress, Backdrop } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2'
import { getThumbnailURL } from 'src/utils/files';
//styles scss
import styles from "../../../styles/videoPage.module.scss";

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

VideoCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function VideoCard({ post, index }) {
  let { cover, title, view, comment, share, author, createdAt, tags, id, video_id, video_url } = post;


  const [downloadReadyStatus, setDownloadReadyStatus] = useState("")
  const [downloadURL, setDownloadURL] = useState("")
  const [loading, setLoading] = useState(false)

  cover = `${process.env.REACT_APP_STREAM_BASE_URL}/${video_id}/thumbnails/thumbnail.jpg?time=8s&height=270`

  if (video_url) {
    cover = getThumbnailURL(video_url)
  }

  const latestPostLarge = true;
  const latestPost = index === 1 || index === 2;

  const navigate = useNavigate()


  const downloadVideoFromLink = (downloadURL, newTab = false) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadURL + `?filename=${makeSlug(title)}.mp4`;
    // the filename you want


    a.download = `${title}.mp4`;
    document.body.appendChild(a);
    if (newTab) {
      a.target = "_blank"
    }
    a.click();
  }

  const makeSlug = (name) => {
    //converting name to lower case then spaces replacing with '-'
    //removing other special chars
    return name
      .trim()
      .toLowerCase()
      .replace(/ /g, "-") // replacing spaces with -
      .replace(/[^\w-]+/g, "") // removing special chars
      .replace(/(\-)\1+/gi, (str, match) => {
        // removing duplicate consecutive '-'
        return match[0];
      });
  };


  const fetchVidoeDownloadStatus = async (loading = true) => {
    try {

      if (loading) {
        setLoading(true)
      }
      const options = {
        method: 'GET',
      }

      let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/videos/` + id + `/download-url`,
        options)

      if (response.status == 200) {
        let respData = await response.json()

        if (respData.data.status == "ready") {
          setDownloadURL(respData.data.url)
          setDownloadReadyStatus("ready")
          downloadVideoFromLink(respData.data.url)
        }
        else {
          setDownloadURL(respData.data.url)
          setDownloadReadyStatus("inprogress")

          Swal.fire({
            title: 'Alert !',
            text: 'Video download not available for this moment! Please try after someting',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false)
    }
  }

  const downloadVideo = async () => {
    if (video_url) {
      downloadVideoFromLink(video_url, true)
    } else {
      await fetchVidoeDownloadStatus()
    }
  }

  const viewVideo = () => {
    navigate('/dashboard/watch-videos/' + id + '/play')
  }


  const POST_INFO = [
    // { number: comment, icon: 'eva:message-circle-fill' },
    { icon: 'eva:eye-fill', action: viewVideo },
    { icon: 'material-symbols:download-rounded', action: downloadVideo },
  ];

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3} lg={latestPostLarge ? 4 : 2} >
      <Card className={styles.videoCard} sx={{ position: 'relative' }}>
        <PlayCircleIcon className={styles.pauseIcon} sx={{ color: "#ffcb05", fontSize: "3rem" }} />

        <StyledCardMedia className={styles.cardImage}
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)',
              },
            }),
          }}

        >
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
              ...((latestPostLarge || latestPost) && { display: 'none' }),
            }}

          />


          <StyledCover alt={title} src={cover} />
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 1,
            ...((latestPostLarge || latestPost) && {
              top: 0,
              width: '100%',
              position: 'absolute',
            }),
          }}
        >

          <div className={styles.hoverCardContent}>
            <StyledInfo>
              {POST_INFO.map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ml: index === 0 ? 0 : 1.5,
                    ...((latestPostLarge || latestPost) && {
                      color: 'white',
                    }),
                    cursor: 'pointer'
                  }}
                  onClick={info.action}
                >
                  <Iconify icon={info.icon} sx={{ width: 26, height: 26, mr: 1.5 }} />
                </Box>
              ))}
            </StyledInfo>
            <Typography gutterBottom variant="caption" sx={{ color: '#ffffff', display: 'block', fontWeight: "700" }}>
              {fDate(createdAt, 'dd MMM yyyy hh:mm a')}
            </Typography>

            <StyledTitle
              color="inherit"
              variant="subtitle2"
              underline="hover"
              sx={{
                ...(latestPostLarge && { typography: 'h6', height: 60 }),
                ...((latestPostLarge || latestPost) && {
                  color: 'common.white',
                }),
              }}
            >
              <Link color="inherit"
                underline="hover" href={'/dashboard/watch-videos/' + id + '/play'}
              > {title}</Link>
            </StyledTitle>

            <StyledInfo>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: "wrap",
                  gap: "10px",
                  ml: 0,
                  ...((latestPostLarge || latestPost) && {
                    color: 'grey.500',
                  }),
                }}
              >

                {tags.map((tag, index) => {
                  return <Typography variant="caption" key="index" > # {tag}
                  </Typography>
                })}
              </Box>
            </StyledInfo>
          </div>
        </CardContent>
      </Card >
      <Card className={styles.mobileVideoCard}>
        <div className={styles.mobileCardImage}>
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
              ...((latestPostLarge || latestPost) && { display: 'none' }),
            }}

          />


          <img alt={title} src={cover} sx={{ objectPosition: "top", padding: "10px" }} />
        </div>
        <div className={styles.cardContentMobile}>
          <Link color="inherit"
            underline="hover" href={'/dashboard/watch-videos/' + id + '/play'} sx={{
              ...(latestPostLarge && { typography: 'h6', height: "inherit" }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white',
              }),
            }}
          > {title}</Link>
          <Typography sx={{ color: '#ffffff', display: 'block', fontWeight: "700" }}>
            {fDate(createdAt, 'dd MMM yyyy hh:mm a')}
          </Typography>


        </div>
        <div className={styles.actionBtn}>
          <MoreVertIcon />
        </div>
      </Card>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid >

  );
}
