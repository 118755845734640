import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import styles from "../styles/watchVideo.module.scss";
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, colors, AppBar, Toolbar, Tooltip, IconButton, Menu, MenuItem, Grid, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../hooks/useResponsive';
import LogoutIcon from '@mui/icons-material/Logout';


import account from '../_mock/account';


MenuIconList.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};
export default function MenuIconList({ openNav, onCloseNav }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const signinSliceData = useSelector((state) => state.Signin.data.user_details);

    const isDesktop = useResponsive('up', 'lg');

    const [anchoreElNav, setAnchoreElNav] = React.useState(null);
    const [anchoreElUser, setAnchoreE1User] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchoreElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchoreE1User(event.currentTarget);
    };
    const handleCloseNavMenu = (event) => {
        setAnchoreElNav(null);
    };
    const handleCloseUserMenu = (event) => {
        setAnchoreE1User(null);
    };

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const handleClose = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        })
        // localStorage.clear();
        navigate("/");
    };

    return (
        <div>
            <Box sx={{
                flexGrow: 0,
                '& .MuiIconButton-root:hover': {
                    background: "none !important",
                },
                '& .MuiButton-root:hover': {
                    background: "none !important",
                },
            }} >
                <Tooltip title="Open Menu">
                    <IconButton sx={{ p: 0 }} >
                        <Button color="inherit" onClick={handleOpenUserMenu}><MenuIcon sx={{ color: "#000000", fontSize: "30px" }} /></Button>
                    </IconButton>
                </Tooltip>

                <Menu
                    sx={{

                        '& .MuiPaper-root': {
                            width: "100%",
                            maxWidth: "inherit",
                            left: "0 !important",
                            top: "0 !important",
                            height: "100%",
                            maxHeight: "inherit",
                            background: "rgba(255, 203, 5, 0.86)",

                        },


                    }}
                    id="menu-appbar"
                    anchorEl={anchoreElUser}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchoreElUser)}
                    onClose={handleCloseUserMenu}
                >

                    <MenuItem onClick={handleCloseUserMenu} >
                        <Grid container >
                            <Grid item xs={12}>

                                <Grid container direction="column" alignItems={'center'} className={styles.menuListGridContainer}>
                                    <MenuItem sx={{
                                        marginBottom: "1rem", display: "flex", flexDirection: "column",

                                        '& .MuiAvatar-root': {
                                            width: "115px",
                                            height: "115px",
                                        },
                                    }}>
                                        <Avatar src={account.photoURL} alt="photoURL" />
                                        <Typography variant='h6'>John Wick</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={() => navigate('/dashboard/view-profile')}>Profile</MenuItem>
                                    <MenuItem onClick={() => navigate('/dashboard/watch-videos')}>Watch Videos</MenuItem>

                                    <MenuItem onClick={() => navigate('/dashboard/update-password')}>Update Password</MenuItem>
                                    <MenuItem onClick={handleClose}>Log Out</MenuItem>
                                    <MenuItem onClose={handleCloseUserMenu}>  <img src="/menu-closeIcon.svg" alt="close" />  </MenuItem>
                                </Grid>

                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Box>

        </div>
    )
}

