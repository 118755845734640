

import * as React from 'react';
import styles from "../styles/watchVideo.module.scss";
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress, Chip } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuIconList from './MenuIconList';
export default function MobileMenu() {
    return (
        <div >   
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" >
                    <Toolbar className={styles.mobileHeader}>
                        <img src="/video-icon.svg" alt="header" />
                        <Typography >
                            Videos
                        </Typography>
                        <MenuIconList/>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}


