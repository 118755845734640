import { Typography } from "@mui/material";


const ErrorMessages = ({ errormessage, pathname }) => {


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }



    return (
        <>
            {Array.isArray(errormessage) && errormessage.find((error) => error.path[0] === pathname) && (
                <Typography color="error" sx={{ fontSize: "clamp(.75rem, .833vw, 1.875rem)" }}>
                    {
                        capitalizeFirstLetter(errormessage.find((error) => error.path[0] === pathname).message)
                    }
                </Typography>
            )}
        </>
    )
}
export default ErrorMessages;