// ----------------------------------------------------------------------


const accounts = [
  {
    displayName: 'Jaydon Frankie',
    email: 'demo@minimals.cc',
    username: "admin",
    user_type: "admin",
    password: "admin@123",
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  },
  {
    displayName: 'Jaydon Frankie',
    email: 'demo@minimals.cc',
    username: "user",
    user_type: "user",
    password: "user@123",
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  }
];


export default accounts;
