import { Button } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import styles from "../styles/videoPage.module.scss";


const ActionButton = ({ onClick, name }) => {

    return (
        <>
            <Button className={styles.newVedioButton} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={onClick}>
                {name}
            </Button>
        </>
    )
}
export default ActionButton;