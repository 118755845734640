import { Backdrop, Button, Container, CircularProgress, LinearProgress, TableHead, Table, TableCell, TableRow, TableBody } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { styled } from '@mui/material/styles';
import styles from "../../styles/videoPage.module.scss";
// import json2csv from 'json2csv';
import { saveAs } from 'file-saver';
import exportFromJSON from "export-from-json";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";

const ImportDocumentForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signinSliceData = useSelector((state) => state.Signin.data);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState()
    const [successResponse, setSuccessResponse] = useState();
    const [dragActive, setDragActive] = React.useState(false);
    const inputRef = React.useRef(null);
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {

        }
    };


    useEffect(() => {
        if (!signinSliceData.access_token) {
            handleLogout();
        }
    }, [])

    const uploadFile = async () => {
        try {

            setLoading(true)
            var formdata = new FormData();
            formdata.append("file", file);
            const options = {
                method: 'POST',
                headers: {
                    authorization: `${signinSliceData.access_token}`,
                },

                body: formdata
            }
            let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/upload-csv-documents`,
                options)
            if (response.status == 200) {
                const responseData = await response.json();
                if (responseData.success) {
                    setSuccessResponse(responseData);
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: responseData.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        didClose: () => {
                            window.location.reload();
                        },
                    })
                }
            }

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }

    }
    const handelChange = (e) => {
        setFile(e.target.files[0]);
        let filename = e.target.value.slice(12,)
    };

    const handleBack = () => {
        navigate('/dashboard/documents')
    }

    const downloadCSV = () => {
        let data = successResponse?.not_inserted_data;
        const fileName = 'download'
        const exportType = exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })

    }


    return (
        <>
            <Button variant="contained" style={{ backgroundColor: "#00A651", maxHeight: "40px" }} onClick={handleBack}>Back</Button>
            <Button variant='contained' style={{ backgroundColor: "#00A651", maxHeight: "40px", marginLeft: "10px" }}>
                <a download="videocms-csv-template" href="/document_template.csv" style={{ textDecoration: "none", color: "white" }}>Download Template</a>
                <FileDownloadOutlinedIcon />
            </Button>
            <Container className={styles.importCsvContainer} maxWidth="sm" textAlign="center">
                <div className={styles.importCsvFile}>
                    <form
                        className="form-file-upload"
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        {successResponse ?
                            <input
                                disabled
                                className="input-file"
                                ref={inputRef}
                                type="file"
                                id="input-file-upload"
                                multiple={true}
                                onChange={handelChange}
                            /> :
                            <input

                                className="input-file"
                                ref={inputRef}
                                type="file"
                                id="input-file-upload"
                                multiple={true}
                                onChange={handelChange}
                            />}
                        <label
                            id="label-file-upload"
                            htmlFor="input-file-upload"
                            className={dragActive ? "drag-active" : ""}
                        >
                        </label>
                        {dragActive && (
                            <div
                                className="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                            ></div>
                        )}
                    </form>
                </div>
                <div>
                    {file
                        ? (!successResponse ? <Button
                            className={styles.submitBtn}
                            onClick={uploadFile}
                            type="submit"
                            variant="contained"
                            style={{ marginTop: "2em" }}
                        >
                            Submit
                        </Button> :
                            <Button
                                className={styles.submitBtn}
                                onClick={uploadFile}
                                type="submit"
                                disabled
                                variant="contained"
                                style={{ marginTop: "2em" }}
                            >
                                Submit
                            </Button>) :
                        <Button
                            className={styles.submitBtn}
                            onClick={uploadFile}
                            type="submit"
                            disabled
                            variant="contained"
                            style={{ marginTop: "2em" }}
                        >
                            Submit
                        </Button>
                    }
                </div>
            </Container>
            {loading ? <LinearProgress sx={{ color: "white" }} /> : ""}

            {successResponse ? <Container>
                <div>
                    <h4 className={styles.csvDataHeader}>Summary</h4>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>Success</TableCell>
                                <TableCell>Failed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{successResponse?.total_count}</TableCell>
                                <TableCell>{successResponse?.success_count}</TableCell>
                                <TableCell>{successResponse?.failed_count}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                {successResponse?.failed_count ?
                    <div>
                        <h4 className={styles.csvDataHeader}>Failed Summary</h4>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Reason for failure</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {successResponse?.not_inserted_data?.slice(0, 5).map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.title}</TableCell>
                                            <TableCell>{item.failure_reason}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    : ""}
                <div className={styles.csvImportButtonGroup}>
                    <Button className={styles.downloadButton} variant="outlined" onClick={downloadCSV}>Download All</Button>
                    <span><Button className={styles.doneButton} onClick={handleBack} variant='contained'>Done</Button></span>
                </div>
            </Container> : ""}
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </>
    )

}

export default ImportDocumentForm;