import { Button, Dialog, TextField, Typography } from "@mui/material";


const AddFolderDialog = ({ setFolderDialogOpen, createNewFolder, setFolderName, folderName }) => {

    return (
        <>
            <Dialog onClose={() => setFolderDialogOpen(false)} open={true} height='300' maxWidth='lg' >
                <div style={{ padding: "10px" }}>
                    <Typography variant="h6">
                        Create Folder
                    </Typography>
                </div>
                <div style={{ padding: "20px", height: '100px', width: "300px", alignContent: "center", justifyContent: "center" }}>
                    <form onSubmit={createNewFolder}>
                        <TextField
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            placeholder='Enter Folder Name...'
                            variant='standard'
                        />
                        {folderName ? <Button type='submit' sx={{ color: "#ffcb03" }}>Create</Button> : <Button disabled>Create</Button>}
                    </form>
                </div>
            </Dialog>
        </>
    )
}
export default AddFolderDialog;