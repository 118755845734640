import { Backdrop, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import styles from "../styles/profileView.module.scss";
import ErrorMessages from "src/Core/ErrorMessages";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MenuIconList from "src/components/MenuIconList";


const UpdetePassword = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessages] = useState(false);
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [passwordError, setPasswordError] = useState(false);


    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        })
        navigate("/");
    };

    const updatePassword = async () => {
        setPasswordErrorMessages("");
        setPasswordError("");
        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/password/update`;
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    "current_password": currentPassword,
                    "new_password": newPassword
                }),
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Updated New Password Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    didClose: () => {
                        handleLogout()
                    }
                })

            }
            else if (responseData.status === 400) {
                setPasswordError(responseData.message.message)
            }
            if (responseData.success === false) {
                setPasswordErrorMessages(responseData.error.issues)
            }

        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    const handleCurrentPasswordVisibilityToggle = () => {
        setCurrentPasswordVisible((prevVisible) => !prevVisible);
    };

    const handleNewPasswordVisibilityToggle = () => {
        setNewPasswordVisible((prevVisible) => !prevVisible);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            updatePassword();
        }
    };



    return (
        <>
            <Button className={styles.webBackBtn} onClick={() => navigate('/dashboard/view-profile')}>Back</Button>
            <div className={styles.navLinksColumn}>
                <Button className={styles.backIcon} onClick={() => navigate('/dashboard/view-profile')} ><KeyboardBackspaceIcon sx={{ color: "#000000" }} /> </Button>
                <MenuIconList />
            </div>
            <Container maxWidth="sm" className={styles.updatePsdPage}>
                <div className={styles.fieldsContainer}>
                    <div className={styles.UpdateTxt}>
                        <Typography>Update Password</Typography>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ background: "#E8F0FE", fontSize: "2rem", width: "100%" }}
                                name="currentPassword"
                                label="Current Password"
                                required
                                type={currentPasswordVisible ? "text" : "password"}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleCurrentPasswordVisibilityToggle}>
                                                {currentPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ErrorMessages errormessage={passwordErrorMessage} pathname="current_password" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ background: "#E8F0FE", fontSize: "2rem", width: "100%" }}
                                name="newPassword"
                                label="New Password"
                                required
                                type={newPasswordVisible ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleNewPasswordVisibilityToggle}>
                                                {newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ErrorMessages errormessage={passwordErrorMessage} pathname="new_password" />
                            {passwordError ? <p style={{ color: "red" }}>{passwordError}</p> : ''}
                        </Grid>
                    </Grid>
                    <div className={styles.updatePsdBtn}>

                        <Button type="submit" className={styles.cancelBtn} onClick={() => navigate('/dashboard/view-profile')} variant="text">Cancel</Button>


                        <Button type="submit" className={styles.updateBtn} onClick={updatePassword} variant="contained">Update</Button>

                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </Container>
        </>
    )
}
export default UpdetePassword;