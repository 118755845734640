import { Autocomplete, Chip, TextField } from "@mui/material";
import stylesSearch from "src/styles/watchVideo.module.scss";


const SearchByTags = ({ onTagsUpdate, updatedtags, tagsOptions, fetchTagsBySearch }) => {

    return (
        <>
            <Autocomplete
                sx={{
                    minWidth: "350px", fontSize: "clamp(.875rem, 0.72vw, 1.125rem)",
                    padding: "0",
                    '& .MuiInputBase-root': {

                        fontSize: "clamp(.875rem, 0.72vw, 1.125rem)",

                    },
                }}
                multiple
                id="tags-filled"
                value={updatedtags}
                onChange={(event, newValue) => {
                    // setTags(newValue.map((e) => e.toLowerCase()));
                    onTagsUpdate(newValue)
                }}
                options={tagsOptions}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip sx={{ margin: "0", height: "22px" }} variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        className={stylesSearch.searchForm}
                        {...params}
                        placeholder="Search by tags..."
                        onChange={fetchTagsBySearch}
                        size="small"
                    />
                )}
            />
        </>
    )
}
export default SearchByTags;