import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import styles from "../styles/videoPage.module.scss";
import ErrorMessages from "./ErrorMessages";


const ResetPasswordDialog = ({ handleClose, resetPassword, setResetPassword, ResetUserPassword, resetErrorMessage, loading1 }) => {


    return (
        <>
            <Dialog
                className={styles.miniDialogBox}
                open={true}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                sx={{
                    '& .MuiPaper-root': {
                        padding: "1rem",
                    },
                    '& .MuiTypography-root': {
                        padding: "0 !important",
                    },
                    '& .MuiDialog-paper': {
                        width: "350px"
                    }
                }}
            >
                <DialogTitle style={{
                    cursor: 'move', fontWeight: "500"
                }} id="draggable-dialog-title">
                    Reset Password
                </DialogTitle>
                <TextField
                    sx={{ background: "#E8F0FE", fontSize: "2rem", marginTop: "1rem" }}
                    name="password"
                    label="Enter New Password"
                    type="password"
                    size="small"
                    value={resetPassword}
                    onChange={(e) => {
                        setResetPassword(e.target.value)
                    }}
                />
                <ErrorMessages errormessage={resetErrorMessage} pathname="password" />
                <DialogActions sx={{ padding: "8px 0 0 !important", marginTop: "1rem" }}>
                    <Button autoFocus onClick={handleClose} variant="outlined" sx={{ color: "#292929", borderColor: "#999999" }}>
                        Cancel
                    </Button>
                    <Button onClick={ResetUserPassword} variant="contained" sx={{ backgroundColor: "#00A651" }}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ResetPasswordDialog;