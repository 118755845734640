import { TextField } from "@mui/material";


const SearchByFolders = ({ folderSearch }) => {

    return (

        <>
            <TextField

                placeholder="Search by folder..."
                onChange={folderSearch}
                size="small"
            />
        </>
    )
}
export default SearchByFolders;