import { Button } from "@mui/material";
import styles from "../styles/videoPage.module.scss";

const ImportCsvButton = ({ onClick, name }) => {

    return (
        <>
            <Button className={styles.newVedioButton} variant="contained" onClick={onClick}>
                {name}
            </Button>
        </>
    )
}
export default ImportCsvButton;