import React, { useEffect, useState } from 'react'
import { startUpload } from './../hooks/uploadFile'
import './fileupload.css'
import { styled } from '@mui/material/styles';

import { Link, Container, Typography, Divider, Stack, Button, TextField, Checkbox, LinearProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));
const Uppytest = () => {



    const [process, setProcess] = useState(0)
    const [buffer, setBuffer] = useState(0)
    const [fileUploadDone, setFileUploadDone] = useState(false)


    useEffect(() => {


    }, [])


    const onUploadCallBack = (bytesUploaded, progressDone) => {

        if (progressDone == 100) {
            setProcess(progressDone)
            setFileUploadDone(true)
        }
        else {
            setBuffer(progressDone + 10)
        }
    }
    const uploadFile = (e) => {
        e.preventDefault();
        const selectFile = document.getElementById("file");
        startUpload(
            selectFile.files[0],
            5 * 1024 * 1024,
            "https://cloudflare-streams-tus-upload.test-blackbaze.workers.dev/upload",
            onUploadCallBack
        );
    }

    return (
        <div className="container">
            <form>
                <div className="form-group">
                    <label for="file">Select Video You Want To Upload</label>
                    <input type="file" id="file" />
                </div>
                <button type="submit" id="upload" onClick={uploadFile}>Upload Video With Tus</button>
                <div id="progress-bar-container">
                    <div id="progress-bar-fill"></div>
                </div>
                <span id="progress-bar-percent"></span>
            </form>


            <Container maxWidth="sm">
                <StyledContent>

                    <Stack spacing={3}>
                        <TextField name="title" label="Title" />

                        <TextField multiline
                            maxRows={4} minRows={2}
                            name="description" label="Description" />

                        <TextField
                            name="tags"
                            label="Tags"
                            placeholder="Enter tags comma seperated"
                            type={'text'}
                        />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    </Stack>


                    <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Upload
                        </Button>
                    </label>

                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="buffer" value={process} valueBuffer={buffer}

                            size={40}
                            thickness={4}

                        />
                    </Box>



                    <LoadingButton fullWidth size="large" type="submit" variant="contained"  >
                        Add Video
                    </LoadingButton>
                </StyledContent>
            </Container>
        </div>
    );
}

export default Uppytest