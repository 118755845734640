import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
//styles scss
import styles from "../styles/documentsPage.module.scss";
// components
import Logo from '../components/logo';
import AddDocumentForm from '../components/AddDocumentForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Height } from '@mui/icons-material';
// sections

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    height: 'calc(100vh - 2rem)',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 1000,
  margin: 'auto',
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 0, 0, 0),
}));

// ----------------------------------------------------------------------

export default function AddDocumentPage() {
  const navigate = useNavigate()
  const mdUp = useResponsive('up', 'md');

  const [searchParams, setSearchParams] = useSearchParams();

  const handleBack = () => {
    let fromPage = searchParams.get("from");
    let view = searchParams.get("view");
    if (fromPage) {
      navigate(`/dashboard/documents/watch-folders/${fromPage}?view=${view}`);
    } else { navigate('/dashboard/documents') }

  }

  return (
    <>
      <Helmet>
        <title> Add Video | Video CMS</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <Button variant="contained" style={{ backgroundColor: " #00A651", maxHeight: "40px" }} onClick={handleBack}>Back</Button>
        <Container maxWidth="lg" textAlign={'center'}>

          <StyledContent>
            <Typography variant="h4" mb={4}>
              Add New Document
            </Typography>
            <AddDocumentForm></AddDocumentForm>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}





