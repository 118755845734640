import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
// components
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Iconify from '../components/iconify';
import { PlayVideoCard, WatchVideoSearch } from '../sections/@dashboard/blog';
import { useState, useEffect } from 'react';
import styles from "../styles/videoPage.module.scss";
// import style from "../styles/watchVideo.module.scss";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import MenuIconList from 'src/components/MenuIconList';
import { useDispatch, useSelector } from 'react-redux';
// mock
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function WatchVideos() {
  const signinSliceData = useSelector((state) => state.Signin.data);


  const dispatch = useDispatch();

  const [video, setVideo] = useState({})

  const [loading, setLoading] = useState(false);


  const navigate = useNavigate()
  let params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();



  const backToHome = () => {
    if (searchParams.get('from')) {
      navigate('/dashboard/videos');
    } else { navigate('/dashboard/watch-videos') }
  }

  const handleLogout = async () => {
    dispatch({
      type: 'SIGN_OUT',
      payload: {},
    });
    navigate('/');
  };

  useEffect(() => {
    fetchVideos()
  }, [])
  const fetchVideos = async () => {
    try {

      setLoading(true)
      const options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          authorization: `${signinSliceData.access_token}`,
        },
      }

      let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/` + 'videos/' + params.id,
        options)

      if (response.status == 200) {
        let respData = await response.json()
        if (respData.success) {
          setVideo(respData.data)
        }
        if (response.status === 401) {
          handleLogout();
        }
      } else {
        setVideo({})
      }


    }
    catch (err) {

      console.log(err)
      setVideo({})

    }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Watch Videos | Video CMS </title>
      </Helmet>


      <Container maxWidth="lg" className={styles.watchVideoContainer}>
        <Button className={styles.backButton} onClick={backToHome}> Back to Videos</Button>
        <div className={styles.mobileBack}>
          <Button className={styles.backIcon} onClick={backToHome}><KeyboardBackspaceIcon sx={{ color: "#000000" }} /> </Button>
          <MenuIconList />
        </div>

        {video && video.title &&
          <Stack spacing={{ xs: 1, md: 3 }}>
            {/* This is a video player scripts */}
            <PlayVideoCard key={video.id} post={video} />

            <Typography variant="h4" alignContent="center">
              {video.title}
            </Typography>


            <Typography className={styles.tag} alignContent="center" >
              {video.tags.map((e, index) => {
                return <span key={index} style={{ marginRight: '10px', background: '#ffcb05' }}> # {e}</span>
              })}
            </Typography>
            <Typography className={styles.tagMobile} alignContent="center" >
              {video.tags.map((e, index) => {
                return <span key={index} style={{ marginRight: '10px', background: '#ffcb05' }}> # {e}</span>
              })}
            </Typography>
            <Typography className={styles.videoDescription} alignContent="center">
              {video.description}
            </Typography>
          </Stack>

        }
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </>
  );
}
