import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField, Chip } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { useState, useEffect } from 'react'
import styles from "../../../styles/watchVideo.module.scss";
import { useSelector } from 'react-redux';


// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------

WatchVideoSearch.propTypes = {
  tags: PropTypes.array.isRequired,
};


export default function WatchVideoSearch({ onTagsUpdate, allTagsList, updatedTags }) {
  const signinSliceData = useSelector((state) => state.Signin.data);

  const [tagsOptions, setTagsOptions] = useState([])
  const [tags, setTags] = useState([])

  const fetchTagsOptions = async (query) => {
    const options = {
      headers: {
        'Content-Type': 'applications/json',
        authorization: `${signinSliceData.access_token}`,
      }
    }

    if (query) {

      let res = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/` + 'tags-of-documents?get_all=true')
      if (res.status == 200) {
        let responseData = await res.json()
        setTagsOptions(responseData.data.map((e) => e.tag))

      } else {
        let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/` + 'tags-of-documents?search_string=' + query, options)
        if (response.status == 200) {
          let respData = await response.json()
          setTagsOptions(respData.data.map((e) => e.tag))
        }
      }
    }
  }

  const fetchTagsBySearch = async (e) => {
    let query = e.target.value
    if (query.length) {
      await fetchTagsOptions(query)
    }
  }
  const fetchTagsOption = async (query) => {
    const options = {
      headers: {
        'Content-Type': 'applications/json'
      }
    }

    let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/` + 'tags-of-documents?get_all=true')
    if (response.status == 200) {
      let respData = await response.json()
      setTagsOptions(respData.data.map((e) => e.tag))
      allTagsList(respData.data.map((e) => e.tag));
    }
  }

  useEffect(() => {
    fetchTagsOption()
  }, [])

  return (

    <Autocomplete
      multiline
      minRows={1}
      maxRows={2}
      sx={{
        width: 1080, fontSize: "10px", padding: "0",

      }}
      multiple
      limitTags={1}
      id="multiple-limit-tags"
      value={updatedTags}
      onChange={(event, newValue) => {
        setTags(newValue.map((e) => e.toLowerCase()));
        onTagsUpdate(newValue)
      }}
      options={tagsOptions}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip sx={{ margin: "0", height: "22px" }} variant="outlined" label={option} {...getTagProps({ index })} key={index} />
        ))
      }
      renderInput={(params) => (
        <TextField
          multiline
          minRows={1}
          maxRows={2}

          className={styles.searchForm}
          {...params}
          placeholder="Search by tags..."
          onChange={fetchTagsBySearch}
          size="small"
        />
      )}
    />
  );
}
