import { Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { UserListHead } from "src/sections/@dashboard/user";
import { fDate } from "src/utils/formatTime";
import Iconify from '../components/iconify';
import Swal from "sweetalert2";
import { ConfirmDeleteDialog } from "src/sections/@dashboard/blog";
import StatusDropDownn from "src/Core/StatusDropDown";
//styles
import styles from "../styles/videoPage.module.scss";
import EditStatusDropDown from "src/Core/EditStatusDropDown";
import SearchByName from "src/Core/SearchByName";
import ResetPasswordDialog from "src/Core/RestPasswordDialog";
import ActionButton from "src/Core/ActionButtons";
import StatusDropDown from "src/Core/StatusDropDown";


const TABLE_HEAD = [
    { id: 'id', label: 'User ID', alignRight: false, width: '10%' },
    { id: 'first_name', label: 'Fullname', alignRight: false, width: '15%' },
    { id: 'email', label: 'Email', alignRight: false, width: '15%' },
    { id: 'mobile', label: 'Phone', alignRight: false, width: '10%' },
    { id: 'username', label: 'Username', alignRight: false, width: '15%' },
    { id: 'user_type', label: 'User Type', alignRight: false, width: '10%' },
    { id: 'status', label: 'Status', alignRight: false, width: '10%' },
    { id: 'updatedAt', label: 'Date', alignRight: false, width: '20%' },
    { id: '' },
];




const AllUsers = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [usersData, setUsersData] = useState("");
    const [resetPassword, setResetPassword] = useState("");
    const [totalCount, setTotalCount] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState({});
    const [search, setSearch] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [open, setOpen] = useState(null);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10)
    const [userDeleteConfimation, setUserDeleteConfimation] = useState(false);
    const [status, setStatus] = useState("");
    const [statusValue, setStatusValue] = useState(false);
    const [resetErrorMessage, setResetErrorMessages] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [loading1, setLoading1] = useState(false)

    const handleClickOpen = () => {
        setPasswordOpen(true);
        setResetErrorMessages("")
    };

    const handleClose = () => {
        setPasswordOpen(false);
        setResetErrorMessages("")
    };


    const goToEditUser = (data) => {
        setOpen(null);
        navigate('/dashboard/users/' + selectedRow + '/edit')
    }

    const goToViewUser = (data) => {
        setOpen(null);
        navigate('/dashboard/users/' + selectedRow + '/view')
    }

    const handleOpenMenu = (event, data) => {
        setOpen(event.currentTarget);
        setSelectedRow(data)
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const onDeleteUser = () => {
        setOpen(null);

        setUserDeleteConfimation(true)
    }

    const handleSearch = () => {
        allUsers();
    };

    const handleSearchInputChange = (event) => {
        setSearch(event.target.value);
    };

    const SearchUserStatus = (event) => {
        setSearchStatus(event.target.value);
    };

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        })
        navigate("/");
    };


    const allUsers = async () => {
        setLoading(true)
        try {
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                setUsersData(responseData.data)
                setTotalCount(responseData.data.total)
                setTotalPages(responseData?.data?.total_pages);
                setCurrentPage(responseData?.data?.page);
            }
            else if (responseData.status === 401) {
                handleLogout()

            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (searchStatus) {
            if (searchStatus != 'All') {
                statusSearch();
            } else {
                allUsers();
            }
        }
    }, [searchStatus, signinSliceData?.access_token]);

    useEffect(() => {
        const delay = 500;
        const debounce = setTimeout(() => {
            if (search) {
                searchUser();
            } else {
                allUsers();
            }
        }, delay);
        return () => clearTimeout(debounce);
    }, [search, signinSliceData?.access_token]);

    const deleteUser = async (id) => {

        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${id}`;
            let options = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                }
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'User Deleted Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                })
                allUsers()
            }
        }
        catch (err) {
            console.error.apply(err);
        }
        finally {
            setLoading(false)
        }
    }

    const updateStatus = async (value, id) => {

        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${id}/update-status`;
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    "status": value,
                }),
            };
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: ' User Status Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });
                setStatusValue(false);
                setStatus("")
                setSearchStatus("All")
                allUsers()
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const searchUser = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users?search_string=${search}`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            };
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                setUsersData(responseData.data);
                setTotalCount(responseData.data.total);
                setTotalPages(responseData?.data?.total_pages);
                setCurrentPage(responseData?.data?.page);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const statusSearch = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users?status=${searchStatus}`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            };
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                setUsersData(responseData.data);
                setTotalCount(responseData.data.total);
                setTotalPages(responseData?.data?.total_pages);
                setCurrentPage(responseData?.data?.page);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const ResetUserPassword = async () => {
        setLoading1(true)
        try {

            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${selectedRow}/reset-password`;
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    "password": resetPassword,
                }),
            };
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                handleClose()
                setResetPassword("");
                Swal.fire({
                    title: 'Success!',
                    text: 'User password reset successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });
            }
            if (responseData.success === false) {
                setResetErrorMessages(responseData.error.issues)
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading1(false)
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        allUsers(newPage, limit);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setLimit(parseInt(event.target.value, 10));
        allUsers(0, parseInt(event.target.value, 10))
    };
    const selectStatus = ["All", "ACTIVE", "INACTIVE"];
    const editStatus = ["ACTIVE", "INACTIVE"]


    const clearSearchString = (value) => {
        setSearch("")
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Container className={styles.dataTable}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>

                    <Typography variant="h4" gutterBottom>
                        Users
                    </Typography>
                    <div style={{ display: "flex", "column-gap": "1em" }}>
                        <SearchByName search={search} handleSearchInputChange={handleSearchInputChange} placeholder={"Search by Full Name and Username"} clearSearchString={clearSearchString} />
                        <StatusDropDown searchStatus={searchStatus} onChange={SearchUserStatus} label={"Select Status"} select={selectStatus} />
                        {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} sx={{ minWidth: "150px", backgroundColor: "#00A651" }} onClick={() => navigate('/dashboard/adduser')} >Add New User</Button> */}
                        <ActionButton onClick={() => navigate('/dashboard/users/add')} name={"Add New User"} />
                    </div>

                </Stack>

                <Scrollbar>
                    <TableContainer>
                        <Table>
                            <UserListHead
                                headLabel={TABLE_HEAD}
                                rowCount={totalCount}
                            />
                            {usersData?.data?.length ?
                                <TableBody>
                                    {usersData.data && usersData.data.map((data, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={styles.tableCell}></TableCell>
                                                <TableCell sx={{ width: "5%", fontSize: "clamp(0.7rem, 0.625vw, 1.75rem) !important" }} className={styles.tableCell}>{data.id}</TableCell>
                                                <TableCell sx={{ width: "10%" }} className={styles.tableCell}>{data.first_name + " " + data.last_name}</TableCell>
                                                <TableCell sx={{ width: "10%" }} className={styles.tableCell}>{data.email}</TableCell>
                                                <TableCell sx={{ width: "5%" }} className={styles.tableCell}>{data.mobile}</TableCell>
                                                <TableCell sx={{ width: "5%" }} className={styles.tableCell}>{data.username}</TableCell>
                                                <TableCell sx={{ width: "5%", fontSize: "clamp(0.65rem, 0.625vw, 1.75rem) !important" }} className={styles.tableCell}>{data.user_type}</TableCell>
                                                <TableCell sx={{
                                                    width: "5%",
                                                    '& .MuiFormControl-root': {
                                                        background: "none !important",
                                                        width: "90px !important",
                                                        maxWidth: "100% !important",
                                                        minWidth: "inherit !important"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        background: '#ffffff !important',
                                                        border: 'none !important',
                                                        borderRadius: 3,
                                                        boxShadow: '0 3px 5px 2px #e3c553 !important',
                                                        color: '#000000 !important',
                                                        fontSize: "clamp(5px, 0.625vw, 1.5rem) !important",
                                                    },
                                                    '& .MuiSelect-select': {
                                                        paddingBlock: "5px !important",
                                                        paddingInline: "8px 15px !important",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        right: "3px",
                                                    }

                                                }}>
                                                    <StatusDropDown searchStatus={data.status ? data.status : status} onChange={(e) => {
                                                        setStatus(e.target.value)
                                                        updateStatus(e.target.value, data.id)
                                                    }} select={editStatus} />
                                                </TableCell>
                                                <TableCell sx={{ width: "25%", whiteSpace: "nowrap" }} >{fDate(data.updatedAt, "dd MMM yyyy hh:mm a")}</TableCell>
                                                <TableCell sx={{ width: "5%" }} align="right">
                                                    <IconButton size="large" color="inherit" onClick={(e) => {
                                                        handleOpenMenu(e, data.id)
                                                    }}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody> : loading == false ? <div style={{ margin: "top", textAlign: "center", marginTop: 150, width: '300px', marginLeft: "160%" }}>
                                    <img src='/assets/no-data.png' width='400px' height='200px' style={{ margin: " 2rem auto", }} />
                                </div> : ''}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                {usersData?.data?.length ?
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} users / Page ${currentPage} of ${totalPages}`}
                    /> : ""}

                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >

                    <MenuItem onClick={goToViewUser}>
                        <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
                        View
                    </MenuItem>

                    <MenuItem onClick={goToEditUser}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>
                    <MenuItem sx={{ color: 'error.main' }} onClick={onDeleteUser}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                    </MenuItem>
                    <MenuItem onClick={handleClickOpen}>
                        Reset Password
                    </MenuItem>
                </Popover>

                {
                    userDeleteConfimation &&
                    <ConfirmDeleteDialog row={selectedRow} type={'document'} onDelete={(status) => {
                        setUserDeleteConfimation(false)
                        if (status) {
                            deleteUser(selectedRow)
                        }
                    }}>

                    </ConfirmDeleteDialog>
                }
                {passwordOpen ?
                    <ResetPasswordDialog handleClose={handleClose} resetPassword={resetPassword} setResetPassword={setResetPassword} ResetUserPassword={ResetUserPassword} resetErrorMessage={resetErrorMessage} loading1={loading1} />
                    : ''}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container >
        </>
    );
}
export default AllUsers;