import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const StatusDropDown = ({ searchStatus, onChange, label, select }) => {


    return (
        <>
            <FormControl
                size="small"
                style={{ minWidth: "150px" }}
            >
                <InputLabel id="demo-simple-select-helper-label" sx={{ fontSize: "clamp(.875rem, 0.52vw, 1.125rem) !important", color: "#000000", fontWeight: 500, fontFamily: "'DM Sans', sans-serif" }}

                >  {label}</InputLabel>
                <Select
                    size="small"
                    label={label}
                    value={searchStatus}
                    onChange={onChange}
                    sx={{ fontSize: "clamp(0.875rem, 0.52vw, 1.125rem)" }}
                >
                    {select.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}
export default StatusDropDown;