import { createSlice } from "@reduxjs/toolkit";


export const SigninSlice = createSlice({
    name: 'Signin',
    initialState: {
        data: {
            user_details: {
            },
            access_token: "",
            refresh_token: ""
        }
    },
    reducers: {
        setData(state, data) {
            state.data = data.payload
        },

    }
})
export const { setData } = SigninSlice.actions

export default SigninSlice.reducer