import { Backdrop, CircularProgress, Typography, Grid } from '@mui/material';
import React, { PureComponent, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles
    from "../styles/dashboard.module.scss";
import moment from 'moment';
import DateRangePickerForDashBoard from 'src/Core/DateRangePicker';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export default function DocumentsDashboardGraph() {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [documentData, setDocumentData] = useState([]);
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: getDefaultStartDate(),
        toDate: getDefaultDate(),
    });
    const [loading, setLoading] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: "SIGN_OUT",
            payload: {},
        })
        navigate("/");
    };
    useEffect(() => {
        if (!signinSliceData.access_token) {
            handleLogout();
        }
    }, [])

    const fetchData = async (from, to) => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/dashboard/document-count?start_date=${from}&end_date=${to}`;
            let options = {
                method: "GET",
                headers: {
                    authorization: `${signinSliceData.access_token}`,
                },
            };

            let res = await fetch(url, options);
            let responseData = await res.json();
            if (responseData.status === 401) {
                handleLogout();
            } else {
                setDocumentData(responseData.data);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (e, dateType) => {
        setSelectedDateRange((prevDateRange) => ({
            ...prevDateRange,
            [dateType]: e.target.value,
        }));
    };
    useEffect(() => {
        let date1 = new Date(moment(new Date(startOfMonth(new Date()))).format("YYYY-MM-DD")).toISOString().substring(0, 10);
        let date2 = new Date(moment(new Date(new Date())).format("YYYY-MM-DD")).toISOString().substring(0, 10);
        fetchData(date1, date2);
        setFromDate(startOfMonth(new Date()))
        setToDate(new Date())
    }, []);

    const data =
        Array.isArray(documentData) &&
        documentData.map((item) => ({
            name: moment(item.date).format("DD-MM-YYYY"),
            count: item.count,
        }));

    //capture the dates
    const captureDateValue = (fromDate, toDate) => {
        if (fromDate && toDate) {
            setFromDate(fromDate)
            setToDate(toDate)
            fetchData(fromDate, toDate)
        }
        else {
            fetchData("", "")
            setFromDate("")
            setToDate("")
        }
    }

    return (
        <div className={styles.dashboardPage}>

            <Grid container spacing={2}>
                <Grid item md={5}>
                    <Typography variant='h3'>Documents Dashboard Graph</Typography>
                </Grid>
                <Grid item md={7}>
                    <div className={styles.dateBlock}>
                        <label>Date Range : </label>
                        <DateRangePickerForDashBoard captureDateValue={captureDateValue} />


                        {/* <input
                            type="date"
                            value={selectedDateRange.fromDate}
                            max={getDefaultDate()} // Add max attribute to prevent selecting future dates
                            onChange={(e) => handleDateChange(e, 'fromDate')}
                        />
                        <span> - </span>
                        <input
                            type="date"
                            value={selectedDateRange.toDate}
                            max={getDefaultDate()} // Add max attribute to prevent selecting future dates
                            onChange={(e) => handleDateChange(e, 'toDate')}
                        /> */}
                    </div>
                </Grid>
            </Grid>
            <div className={styles.graphWrapper}>
                {data.length ?
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <XAxis dataKey="name" scale="point" padding={{ left: 0, right: 0 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Area type="monotone" dataKey="count" stroke="#ff4444" fill="#FFCB05" />
                        </AreaChart>
                    </ResponsiveContainer>
                    : <div style={{ margin: "top", textAlign: "center", marginTop: 150 }}>
                        <img src='/assets/no-data.png' width='150px' height='200px' style={{ margin: " 2rem auto" }} />
                    </div>}
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

function getDefaultDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


function getDefaultStartDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const startDate = '01';
    return `${year}-${month}-${startDate}`;
}