import { Avatar, Backdrop, Button, CircularProgress, Container, Dialog, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Typography } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import { useEffect, useState } from "react";
import SingelFolderFiles from "./SingelFolderFiles";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
//styles scss
import styles from "../styles/folder.module.scss";
import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AddIcon from '@mui/icons-material/Add';
// import stylesearch from "../../../styles/watchVideo.module.scss";

import stylesearch from "../styles/watchVideo.module.scss";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "src/Core/ActionButtons";
import SearchByFolders from "src/Core/SearchByFolders";
import AddFolderDialog from "src/Core/AddFolderDialog";


export default function Folders() {
    const signinSliceData = useSelector((state) => state.Signin.data.user_details);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    let view = searchParams.get('view');
    const [folders, setFolders] = useState([])
    const [loading, setLoading] = useState(false);
    const [folderDialogOpen, setFolderDialogOpen] = useState(false);
    const [folderName, setFolderName] = useState('');


    const [viewMode, setViewMode] = useState(view);



    const NoData = () => {
        return (
            <div style={{ border: "1px solid", width: '300px', height: '300px' }}>
                No Data for the search result
            </div>
        )
    }
    const GridView = (props) => {
        const [searchParams, setSearchParams] = useSearchParams();
        let view = searchParams.get('view');




        return <Grid container>
            <Grid item xs={12} className={styles.gridViewConatiner} >
                {props.folders ? props.folders.map((post, index) => (
                    // <p index={index}><Button className={styles.folderNavigation} variant='contained' onClick={() => navigate(`/dashboard/watch-Files/${post.folder}/files`)}>{post.folder}</Button></p>
                    // onClick = {() => navigate(`/dashboard/watch-Files/${post.folder}/files`)}
                    <Link className={styles.link} to={`/dashboard/documents/watch-folders/${post.folder}?view=${view}`} style={{ textDecoration: "none" }}>
                        <List key={index} sx={{ padding: "0" }}>
                            <ListItem className={styles.listedFolders}>
                                <ListItemAvatar>
                                    <FolderIcon sx={{ color: " #00A651", height: "40px", width: "40px" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    className={styles.listItemText}
                                    primary={`${post.folder[0].toUpperCase() + post.folder.slice(1, 15)}${post.folder.length > 15 ? "..." : ""}`}
                                    secondary={post.document_ids.length}
                                />
                            </ListItem>
                        </List>
                    </Link>
                )) : <NoData />}
            </Grid>
        </Grid>
    }
    const ListView = (props) => {
        const [searchParams, setSearchParams] = useSearchParams();
        let view = searchParams.get('view');


        return <Grid container sx={{ marginTop: "1rem" }}>
            <Grid item xs={12} sx={{ display: "flex", gap: "1%", flexWrap: "wrap", flexDirection: "row" }}>
                {props.folders ? props.folders.map((post, index) => (
                    <Link className={styles.listViewFolders} to={`/dashboard/documents/watch-folders/${post.folder}?view=${view}`} >
                        <List key={index} sx={{ padding: "0" }}>
                            <ListItem sx={{ padding: "0" }}>
                                <ListItemAvatar sx={{ minWidth: "35px" }}>
                                    <FolderIcon sx={{ color: " #00A651", height: "25px", width: "25px" }} />
                                </ListItemAvatar>
                                <ListItemText
                                    className={styles.listItemTextListView}
                                    primary={`${post.folder[0].toUpperCase() + post.folder.slice(1, 15)}${post.folder.length > 15 ? "..." : ""}(${post.document_ids.length})`}
                                // secondary={post.document_ids.length}

                                />
                            </ListItem>
                        </List>
                    </Link>
                )) : <NoData />}
            </Grid>
        </Grid>
    }

    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };

    useEffect(() => {
        if (!searchParams.get('view')) {
            navigate({
                search: `?${createSearchParams({ view: 'grid' })}`,
            });
        }
        fetchFolders();
    }, [])

    const fetchFolders = async (searchString = '') => {
        try {
            setLoading(true)
            const options = {
                method: "GET",
            }
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/` + 'folders'

            if (searchString) {
                url += `?search_string=${searchString}`
            }
            let response = await fetch(url, options)
            if (response.status == 200) {
                let responseData = await response.json()
                if (responseData.success) {
                    setFolders(responseData.data)
                }
            } else {
                if (response.success) {
                    setFolders([])
                }
            }
            if (response.status === 401) {
                handleLogout();
            }
        }
        catch (err) {
            console.log(err)
            setFolders([])
        }
        finally {
            setLoading(false)
        }
    }

    const handleBack = () => {
        navigate('/dashboard/documents')
    }

    const setGridView = () => {
        navigate({
            search: `?${createSearchParams({ view: 'grid' })}`,
        });
        setViewMode('grid')
    }
    const setListView = () => {
        navigate({
            search: `?${createSearchParams({ view: 'list' })}`,
        });
        setViewMode('list')
    }
    const folderSearch = (e) => {

        setTimeout(() => {
            if (e.target.value == '') {
                fetchFolders();
            } else {
                fetchFolders(e.target.value);
            }
        }, 600);

    }
    const createNewFolder = async (e) => {
        e.preventDefault();
        if (folderName) {
            try {
                let url = `${process.env.REACT_APP_LOCAL_API_URL}/` + 'folder'
                const data = { folder: folderName }
                const options = {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: new Headers({
                        "content-type": "application/json"
                    })
                }

                let response = await fetch(url, options);
                let responseData = await response.json();

                if (response.status == 200) {
                    Swal.fire({
                        timer: 1000,
                        showConfirmButton: false,
                        title: "Success!",
                        icon: "success",
                        text: `New Folder ${folderName} created succesfully`
                    })
                    fetchFolders();
                } else {
                    Swal.fire({
                        timer: 1000,
                        showConfirmButton: false,
                        title: "Please try again",
                        icon: "warning",
                        text: `New folder ${folderName} creation error`
                    })
                }
            } catch (e) {
                console.log(e);
            }
            finally {
                setFolderDialogOpen(false);
            }
        }
    }
    return (
        <>
            {/* <Button variant="contained" style={{ backgroundColor: "#ff9100", maxHeight: "40px" }} onClick={handleBack}>Back</Button> */}
            <div style={{ alignItems: "center" }}>
                <Container className={styles.dataTable}>
                    <div className={styles.folderPageHeader} >
                        <div className={styles.navigationLinks}>
                            <Link to='/dashboard/documents'>Documents</Link>
                            <Link to={`?view=${view}`}>Folders</Link>
                        </div>



                        <div style={{ background: "transparent" }}>
                            <SearchByFolders folderSearch={folderSearch} />
                        </div>
                        {signinSliceData.user_type == "VIEWER" ? ' ' :

                            <ActionButton onClick={() => setFolderDialogOpen(true)} name={"Create Folder"} />

                        }
                    </div>
                    <div className={styles.viewButtons}>
                        <Stack maxWidth={350} mb={1} direction="row" alignItems="right" justifyContent="right">
                            <Button className={styles.button} onClick={setGridView} sx={{ color: searchParams.get('view') == 'grid' ? "red" : "#05a551", border: searchParams.get('view') == 'grid' ? "solid" : "" }} >
                                <ViewCompactRoundedIcon sx={{ fontSize: "30px" }} />
                            </Button>
                            <Button className={styles.button} onClick={setListView} sx={{ color: searchParams.get('view') == 'list' ? "red" : "#05a551", border: searchParams.get('view') == 'list' ? "solid" : "" }}>
                                <ListRoundedIcon sx={{ fontSize: "30px" }} />
                            </Button>
                        </Stack>

                    </div>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                        {/* <Typography variant="h4" gutterBottom>
                        Watch Folders
                    </Typography> */}

                    </Stack>
                    {view == 'grid' ? <GridView folders={folders} /> :
                        <ListView folders={folders} />}

                </Container>

                {folderDialogOpen ?
                    <AddFolderDialog setFolderDialogOpen={setFolderDialogOpen} createNewFolder={createNewFolder} setFolderName={setFolderName} folderName={folderName} />
                    : ''}
            </div>

            {!folders.length && !loading ?
                <img src='/assets/no-data.png' width='150px' height='200px' style={{ margin: " 2rem auto" }} />
                : ""}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    )
}
