
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Checkbox, LinearProgress, Box, Autocomplete, Chip, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
//styles scss
import styles from "../styles/videoPage.module.scss";
import { Uppy } from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Tus from '@uppy/tus';
import { formatBytes } from '../utils/formatNumber'
import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';
import { getThumbnailURL } from 'src/utils/files';
import { useDispatch, useSelector } from 'react-redux';


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


const createUppy = (callback) => {
    // Adding to global `meta` will add it to every file.
    // Every Uppy instance needs a unique ID.
    const uppy = new Uppy({})
    uppy.use(ThumbnailGenerator).use(Tus, {
        endpoint: `${process.env.REACT_APP_LOCAL_API_URL}/upload`,
        onAfterResponse: (req, res) => {

            console.log(res)
            // Get uid (video ID) here
            let uid = res.getHeader("Stream-Media-Id");
            callback(uid)
        }
    });
    return uppy
}

const EditVideoForm = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [videoId, setVideoId] = useState("");
    const [videoURL, setVideoURL] = useState("");

    const [videoMetaName, setVideoMetaName] = useState("");
    const [videoMetaType, setVideoMetaType] = useState("");
    const [videoMetaSize, setVideoMetaSize] = useState(0);
    const [loading, setLoading] = useState(false);


    const selectVideoId = (id) => {
        setVideoId(id)
    }


    const [buffer, setBuffer] = useState(0)
    const [fileUploadDone, setFileUploadDone] = useState(false)

    const [uppy, setUppy] = useState(() => createUppy(selectVideoId));
    const [selecteFileDetails, setSelecteFileDetails] = useState({});
    const [fileUploadedSuccess, setFileUploadedSuccess] = useState({});


    const fetchTagsOptions = async (search) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                authorization: `${signinSliceData.access_token}`,
            },
        }
        if (search && search?.length) {
            let response = await fetch(`${process.env?.REACT_APP_LOCAL_API_URL}/tags?search_string=` + search, options)
            if (response.status == 200) {
                let respData = await response.json()
                setTagsOptions(respData.data.map((e) => e.tag))
            }
        }
        else {
            setTagsOptions([])
        }
    }

    useEffect(() => {
        const uppy = new Uppy({})
        uppy.use(ThumbnailGenerator).use(Tus, {
            chunkSize: 1024 * 1024 * 10,
            endpoint: `${process.env.REACT_APP_LOCAL_API_URL}/upload`,
            onAfterResponse: (req, res) => {
                // Get uid (video ID) here
                let uid = res.getHeader("Stream-Media-Id");
                setVideoId(uid)
            },
            onSuccess: function (upload) {
                console.log('Download %s from %s', upload.file.name, upload.url)
            },
        });
        setUppy(uppy)
    }, [])

    uppy.on('file-added', (file) => {
        setSelecteFileDetails(file)
    });
    uppy.on('upload-success', (file) => {
        setFileUploadedSuccess(file.data)
        setVideoMetaName(file.data.name)
        setVideoMetaSize(file.data.size)
        setVideoMetaType(file.data.type)
    });

    uppy.on('thumbnail:generated', (file, preview) => {
        console.log(file, preview)
    })

    const setVideo = (data) => {
        setTitle(data.title)
        setDescription(data.description)
        setTags(data.tags)
        setVideoId(data.video_id)
        setVideoURL(data.video_url)

        const metaData = {
            name: data.video_meta_name,
            type: data.video_meta_type,
            size: data.video_meta_size,
        }
        setSelecteFileDetails(metaData)
        setFileUploadedSuccess(metaData)

        setVideoMetaName(metaData.name)
        setVideoMetaSize(metaData.size)
        setVideoMetaType(metaData.type)

    }

    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };

    useEffect(() => {
        fetchVideos()
    }, []);

    const fetchVideos = async () => {
        try {

            setLoading(true)
            const options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            }

            let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}/videos/` + params.id, options)
            console.log(response);
            let respData = await response.json()
            console.log(respData);
            if (response.status == 200) {

                if (respData.success) {
                    setVideo(respData.data)
                }

            } else {
                setVideo({})
            }
            if (response.status === 401) {
                handleLogout();
            }
        }
        catch (err) {
            console.log(err)
            setVideo({})
        }
        finally {
            setLoading(false)
        }
    }

    const submitUpdateVideo = async () => {

        setLoading(true)
        try {

            const data = {
                video_id: videoId,
                title: title,
                description: description,
                tags: tags,
                thumbnail: "",
                video_meta_name: videoMetaName,
                video_meta_type: videoMetaType,
                video_meta_size: videoMetaSize,
                video_url: videoURL
            }
            const options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify(data)
            }

            let response = await fetch(`${process.env?.REACT_APP_LOCAL_API_URL}/videos/` + params.id,
                options)
            if (response.status == 200) {
                const respData = await response.json()
                if (respData.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Video Updated Successfully',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                        didClose: () => {
                            navigate('/dashboard/videos')
                        }
                    })
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Something went wrong !',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong !',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                })
            }


        }
        catch (err) {

            console.log(err)
            Swal.fire({
                title: 'Error!',
                text: 'Do you want to continue',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            })
        }
        finally {
            setLoading(false)
        }

    }


    useEffect(() => {


    }, [])


    const checkDisable = () => {
        if (videoId || videoURL) {
            return !title?.length || !tags?.length
        }
        else {
            return true
        }
    }





    const removeFile = (e) => {
        uppy.removeFile(selecteFileDetails.id)
        setSelecteFileDetails({})
    }


    return (
        <Container className={styles.addVideoFormContainer}>
            <div style={{ width: '50%', margin: "1rem auto 0" }}>
                <div className={styles.leftBlock} >
                {uppy && <Stack spacing={3}>
                   
                    {!videoId && selecteFileDetails && selecteFileDetails.name && <span> {

                        selecteFileDetails ? selecteFileDetails.name : ""} <button onClick={removeFile}>Remove</button></span>}

                    {videoId && fileUploadedSuccess &&
                        <div>
                            <div className={styles.imageBlock}>
                                <img src={`${process.env.REACT_APP_STREAM_BASE_URL}/${videoId}/thumbnails/thumbnail.jpg?time=8s&height=270`} />
                            </div>


                            <p>
                                <span> {fileUploadedSuccess.name}</span>
                                <span style={{ color: "red" }}> {formatBytes(fileUploadedSuccess.size)}</span>
                            </p>
                        </div>}

                </Stack>}
                </div>

                <Stack spacing={5} mb={5}>
                    <TextField multiline
                        maxRows={4} minRows={2}
                        value={videoURL}
                        name="video url" label="Video URL"
                        onChange={(event) => {
                            setVideoURL(event.target.value);
                        }}
                    />
                    {videoURL &&
                        <div>
                            <div>
                                <img width="200" src={getThumbnailURL(videoURL)} />
                            </div>
                        </div>}
                </Stack>

                <Stack spacing={3}>
                    <TextField name="title"
                        value={title}
                        onChange={(event) => {
                            setTitle(event.target.value);
                        }}
                        label="Title" />

                    <TextField multiline
                        value={description}

                        maxRows={4} minRows={2}
                        name="description" label="Description"
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                    />


                    <Autocomplete
                        multiple
                        id="tags-filled"
                        value={tags?.length ? tags : []}
                        onChange={(event, newValue) => {
                            setTags(newValue.map((e) => e.toLowerCase()));
                        }}
                        options={tagsOptions}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e) => {
                                    fetchTagsOptions(e.target.value)
                                }}
                                label="Tags"
                                sx={{ background: "white" }}
                            />
                        )}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                </Stack>

                <LoadingButton className={styles.updateButton} fullWidth size="large" type="submit" variant="contained" disabled={checkDisable()}
                    loading={loading}
                    onClick={submitUpdateVideo}
                >
                    Update Video
                </LoadingButton>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container >
    );
}

export default EditVideoForm