import { FormControl, InputAdornment, TextField, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


const SearchByName = ({ search, handleSearchInputChange, placeholder, clearSearchString }) => {

    return (
        <>
            <FormControl
                size="small"
                style={{ minWidth: "350px", height: "100%" }}
            >
                <TextField
                    size="small"
                    value={search}
                    placeholder={placeholder}
                    onChange={handleSearchInputChange}
                    InputProps={{

                        endAdornment: (
                            <InputAdornment position="start">

                                {search ?
                                    <IconButton onClick={clearSearchString}>
                                        <img src="/canelicon.svg" width={20} height={20} />

                                    </IconButton> : <InputAdornment position="start">
                                        <img src="/searchicon.svg" width={20} height={20} />
                                    </InputAdornment>}
                            </InputAdornment>
                        )
                    }}
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="start">
                    //             <SearchIcon />
                    //         </InputAdornment>
                    //     )
                    // }}
                    sx={{
                        '& .MuiInputBase-root': {
                            borderRadius: "3px",
                            padding: "0",
                            fontSize: "clamp(.875rem, 0.72vw, 1.125rem)",
                            color: "#000000 !important",
                            fontWeight: "500",
                            fontFamily: "'DM Sans', sans-serif",
                            height: "100%"
                        },
                    }}
                />
            </FormControl>
        </>
    )
}
export default SearchByName;