import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, colors, AppBar, Toolbar, Tooltip, IconButton, Menu, MenuItem, Grid, Container } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//scss styles
import styles from "../../../styles/index.module.scss";
//
import navConfig from './config';
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import OperatorNavConfig from './OperatorNavConfig';
import ViewerNavConfig from './ViewerNavConfig';
// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const signinSliceData = useSelector((state) => state.Signin.data.user_details);

  const isDesktop = useResponsive('up', 'lg');

  const [anchoreElNav, setAnchoreElNav] = React.useState(null);
  const [anchoreElUser, setAnchoreE1User] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchoreElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchoreE1User(event.currentTarget);
  };
  const handleCloseNavMenu = (event) => {
    setAnchoreElNav(null);
  };
  const handleCloseUserMenu = (event) => {
    setAnchoreE1User(null);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const handleClose = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    })
    // localStorage.clear();
    navigate("/");
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', background: "#ffcb05" },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>


      {signinSliceData.user_type == "ADMIN" && <NavSection data={navConfig} />}
      {signinSliceData.user_type == "VIEWER" && <NavSection data={ViewerNavConfig} />}
      {signinSliceData.user_type == "OPERATOR" && <NavSection data={OperatorNavConfig} />}

      {/* <Button onClick={handleClose} color='inherit' sx={{ marginRight: "120px" }}><LogoutIcon />Sign Out</Button> */}
      <div style={{ padding: "0 0.5rem" }}>

        {/* <Button className={styles.menuItem} onClick={() => navigate('/dashboard/view-profile')} sx={{ color: "#616161" }}><AccountBoxOutlinedIcon /> Profile</Button> */}
        {/* <Button className={styles.menuItem} onClick={() => navigate('/dashboard/update-password')} sx={{ color: "#616161" }}> <SecurityUpdateGoodIcon />Update Password</Button> */}
        <Button className={styles.menuItem} onClick={handleClose} sx={{ color: "#616161" }}><LogoutIcon />Sign Out</Button>

      </div>

      <Box sx={{ flexGrow: 1 }}></Box>
    </Scrollbar >
  );

  return (
    <Box
      component="nav"
      sx={{
        width: "200px",
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',

            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
