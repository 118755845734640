import { Backdrop, Button, CircularProgress, Grid, Avatar, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import account from '../_mock/account';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';

import styles from "../styles/profileView.module.scss";
import MenuIconList from "src/components/MenuIconList";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ViewProfile = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);
    const navigate = useNavigate();

    const [profile, setProfile] = useState("");
    const [loading, setLoading] = useState(false);


    const viewProfile = async () => {

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/profile`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                setProfile(responseData.data)

            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        viewProfile();
    }, [])


    const [searchParams, setSearchParams] = useSearchParams();

    const backToHome = () => {
        if (searchParams.get('from')) {
            navigate('/dashboard/videos');
        } else { navigate('/dashboard/watch-videos') }
    }


    return (
        <>
            <div className={styles.navLinksColumn}>
                <Button className={styles.backIcon} onClick={backToHome}><KeyboardBackspaceIcon sx={{ color: "#000000" }} /> </Button>
                <MenuIconList />
            </div>
            <div className={styles.profileViewPage}>
                <Grid container spacing={4} direction="row" justifyContent="center">
                    <Grid item lg={3}>
                        <div className={styles.header}>
                            <Avatar src={account.photoURL} alt="photoURL" className={styles.profilePic} />
                        </div>
                    </Grid>
                    <Grid item md={7} lg={8}>
                        <div className={styles.userDetailContainer}>
                            <Typography variant="h4" className={styles.legend}>Profile</Typography>
                            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>First Name</Typography>
                                        <p className={styles.value} >{profile.first_name}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>Last Name</Typography>
                                        <p className={styles.value} >{profile.last_name}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>Email</Typography>
                                        <p className={styles.value} >{profile.email}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>Mobile Number</Typography>
                                        <p className={styles.value} >{profile.mobile}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>UserName</Typography>
                                        <p className={styles.value} >{profile.username}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.gridItem}>
                                        <Typography variant="h6" className={styles.name}>User Type</Typography>
                                        <p className={styles.value} >{profile.user_type}</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className={styles.buttonGroup}>
                                    <Button className={styles.editBtn} variant="contained" onClick={() => navigate('/dashboard/edit-profile')}>
                                        <EditIcon sx={{ fontSize: 20 }}></EditIcon>
                                        Edit Profile
                                    </Button>
                                    <Button className={styles.updatePassword} variant="contained" onClick={() => navigate('/dashboard/update-password')}>
                                        <KeyIcon sx={{ fontSize: 20 }}></KeyIcon>
                                        Update Password
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default ViewProfile;