import { combineReducers, configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import SigninSlice from "src/StoreSlice/AuthSlice";
import pesistStorage from "src/pesistStorage"
import { persistStore } from "redux-persist";

const appReducer = combineReducers({
    Signin: SigninSlice,

})

const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT") {
        storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: "video_cms_v4",
    version: 2,
    storage: pesistStorage
};

/* istanbul ignore next */
const persistedReducer = persistReducer(persistConfig, rootReducer); // Create a new reducer with our existing reducer

/* istanbul ignore next */
const store = configureStore({
    reducer: persistedReducer,

});



const persistor = persistStore(store)
/* istanbul ignore next */

export {
    persistor,
    store
}


