import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, Button, Card, Container, CircularProgress, Divider, Grid, Typography } from "@mui/material";
//styles
import styles from "../styles/viewUser.module.scss";

const ViewUser = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [userName, setUserName] = useState("");
    const [userType, setUserType] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };

    const viewUser = async () => {

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/${params.id}`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            }

            let res = await fetch(url, options);
            let responseData = await res.json();

            setFirstName(responseData.data["first_name"]);
            setLastName(responseData.data["last_name"]);
            setMobile(responseData.data["mobile"]);
            setUserName(responseData.data["username"]);
            setUserType(responseData.data["user_type"]);
            setEmail(responseData.data["email"]);

            if (responseData.status === 401) {
                handleLogout();
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        viewUser();
    }, [])



    return (
        <>
            <Button type="submit" sx={{ background: "#00a651", color: "white" }} onClick={() => navigate('/dashboard/users')} variant="outlined">Back</Button>
            <Container className={styles.viewProfile}>


                <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                    <Card variant="outlined" className={styles.userProfileView} >
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h4" gutterBottom sx={{ textAlign: "left" }}>
                                    Users Profile
                                </Typography>
                            </div>
                            <Divider variant="middle" style={{ width: "100%", margin: "20px 0" }} />
                            <div>
                                <Grid container>

                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>First Name</p>
                                            <p className={styles.value}>{firstName}</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>Last Name</p>
                                            <p className={styles.value}>{lastName}</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>Email</p>
                                            <p className={styles.value}>{email}</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>Mobile Number</p>
                                            <p className={styles.value}>{mobile}</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>User Name</p>
                                            <p className={styles.value}>{userName}</p>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6}>
                                        <div>
                                            <p className={styles.label}>User Type</p>
                                            <p className={styles.value}>{userType}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </div>
                        </div>
                    </Card>
                </div>
            </Container>
        </>

    );


}
export default ViewUser;