import { Backdrop, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import Swal from "sweetalert2";
import styles from "../styles/user.module.scss";
import ErrorMessages from "src/Core/ErrorMessages";

const AddUser = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [userType, setUserType] = useState("");
    const [loading, setLoading] = useState(false);
    const [addUserErrorMessage, setAddUserErrorMessages] = useState(false);

    const handleLogout = async () => {
        dispatch({
            type: 'SIGN_OUT',
            payload: {},
        });
        navigate('/');
    };

    useEffect(() => {
        if (!signinSliceData.access_token) {
            handleLogout();
        }
    }, [])


    const addUser = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users`;
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    "first_name": firstName,
                    "last_name": lastName,
                    "mobile": mobile,
                    "email": email,
                    "password": password,
                    "username": userName,
                    "user_type": userType,
                }),
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'User Added Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    didClose: () => {
                        navigate("/dashboard/users")
                    }
                })
            }
            // if (responseData.status === 400 || responseData.status === 422) {
            //     setAddUserErrorMessages(responseData.error.issues)
            //    
            // }
            if (responseData.success === false) {
                setAddUserErrorMessages(responseData.error.issues)
            }
            if (responseData.status === 401) {
                handleLogout();
            }

        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Container maxWidth="xl" id={styles.addUser}>
            <div className={styles.container} >
                <div>
                    <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Add Users
                    </Typography>
                </div>
                <Card variant="outlined" className={styles.addUserCard} >
                    <div className={styles.containerBlock}>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="First Name"
                                    label="First Name*"
                                    type={"text"}
                                    size="small"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="first_name" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Last Name"
                                    label="Last Name*"
                                    type={"text"}
                                    size="small"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="last_name" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Email"
                                    label="Email*"
                                    type="email"
                                    size="small"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="email" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="Phone"
                                    label="Mobile Number*"
                                    type="number"
                                    size="small"
                                    value={mobile}

                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setMobile(e.target.value);
                                        }
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="mobile" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="User Name"
                                    label="User Name*"
                                    type="text"
                                    size="small"
                                    value={userName}
                                    onChange={(e) => {
                                        setUserName(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="username" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    sx={{ background: "#E8F0FE", fontSize: "1rem", width: "100%" }}
                                    name="password"
                                    label="Password*"
                                    type="password"
                                    size="small"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                />
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="password" />
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={{ xs: 3, lg: 0 }}>
                            <Grid item xs={12} lg={6}>
                                <FormControl fullWidth sx={{

                                    '& .MuiInputBase-root': {
                                        minHeight: "47px,"
                                    }
                                }}>
                                    <InputLabel id="demo-simple-select-label" size="small">User Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={userType}
                                        label="User Type*"
                                        size="small"
                                        onChange={(e) => {
                                            setUserType(e.target.value)
                                        }}
                                    >
                                        <MenuItem value="ADMIN">ADMIN</MenuItem>
                                        <MenuItem value="VIEWER">VIEWER</MenuItem>
                                        <MenuItem value="OPERATOR">OPERATOR</MenuItem>
                                    </Select>
                                </FormControl>
                                <ErrorMessages errormessage={addUserErrorMessage} pathname="user_type" />
                            </Grid>
                        </Grid>

                    </div>

                    <div style={{ display: "flex", columnGap: "1em", marginTop: "2.5rem" }}>
                        <Button className={styles.cancelBtn} type="submit" sx={{ width: "40%", color: "#000000", background: "#ffffff", fontSize: "1.1rem", fontWeight: "500", border: "none" }} onClick={() => navigate('/dashboard/users')} variant="outlined">Cancel</Button>
                        <Button type="submit" className={styles.submitBtn} sx={{ width: "60%", fontSize: "1.1rem", fontWeight: "500", background: "#00a651" }} onClick={addUser} variant="contained">Submit</Button>

                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Card>
            </div>
        </Container>
    )
}
export default AddUser;