import React, { useEffect, useState } from 'react';
import DocumentsDashboardGraph from 'src/pages/DocumentsDashboardGraph';
import VideosDashboardGraph from './VideosDashboardGraph';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function DashboardAppPage() {
  const signinSliceData = useSelector((state) => state.Signin.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeGraph, setActiveGraph] = useState('');

  const handleGraphButtonClick = (graph) => {
    setActiveGraph(graph);
  };

  const handleLogout = async () => {
    dispatch({
      type: 'SIGN_OUT',
      payload: {},
    });
    navigate('/');
  };

  useEffect(() => {
    if (!signinSliceData.access_token) {
      handleLogout();
    }
  }, [])

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      {/* <div>
        <Button onClick={() => handleGraphButtonClick('videos')}>Videos Dashboard Graph</Button>
        <Button onClick={() => handleGraphButtonClick('documents')}>Documents Dashboard Graph</Button>
      </div>
      {activeGraph === 'videos' && <VideosDashboardGraph />}
      {activeGraph === 'documents' && <DocumentsDashboardGraph />} */}
      <VideosDashboardGraph />
      <DocumentsDashboardGraph />
    </div>
  );
}