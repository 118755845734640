import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
//styles
import styles from "../styles/loginPage.module.scss";
// sections
import { LoginForm } from '../sections/auth/login';
import SignIn from 'src/Auth/SignIn';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
 width:'100%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Video CMS </title>
      </Helmet>

      <StyledRoot className={styles.loginPage}>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection className={styles.leftBlock}>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography> */}
            <img src="/assets/images/tdp-logo.jpg" alt="login" />
          </StyledSection>
        )}

        <div className={styles.rightBlock}>
          <div className={styles.mobileHeaderLogo}>
          <img src="/mobile-header.png" alt="header"/>
          </div>
          <StyledContent className={styles.container}>
            <img src="/assets/lOGO.svg" alt="logo" className={styles.logo} />
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            <SignIn />
          </StyledContent>
        </div>
      </StyledRoot>
    </>
  );
}
