// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },

  {
    title: 'Videos',
    path: '/dashboard/videos',
    icon: icon('ic_blog'),
  },
  {
    title: 'Document',
    path: '/dashboard/documents',
    icon: icon('ic_documents'),
  },

  {
    title: 'Watch Videos',
    path: '/dashboard/watch-videos',
    icon: icon('ic_play_video'),
  },
  {
    title: 'Users',
    path: 'users',
    icon: icon('ic_users'),
  },
  {
    title: 'Profile',
    path: '/dashboard/view-profile',
    icon: icon('ic_user_profile'),
  },
  {
    title: 'Update Password',
    path: '/dashboard/update-password',
    icon: icon('ic_update'),
  },
  // {
  //   title: 'Sign Out',
  //   path: '/',
  //   icon: icon('ic_logout'),
  // },





];

export default navConfig;
