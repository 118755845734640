import moment from "moment";
import { useEffect, useState } from "react";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.css";
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';


const DateRangePickerForDashBoard = ({ captureDateValue, disabled = false, data, dataCols, clearDataButton, setClearDataButton }) => {

    const [fromDate, setFromDate] = useState();
    const [clear, setClear] = useState(false)

    useEffect(() => {
        if (clear == true) {
            setFromDate([])
        }
        else {
            let date1 = new Date(moment(startOfMonth(addMonths(new Date(), -1))).format("YYYY-MM-DD")).toISOString().substring(0, 10);
            let date2 = new Date(moment(endOfMonth(addMonths(new Date(), -1))).format("YYYY-MM-DD")).toISOString().substring(0, 10);
            setFromDate([startOfMonth(new Date()), new Date()])
        }
    }, [clear])

    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), addDays(new Date(), +1)],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 3 months',
            value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'

        },



    ];


    return (
        <Stack
        >
            <DateRangePicker
                placement="bottomEnd"
                disabledDate={(date) => {
                    return date.getTime() > new Date().getTime();
                }}
                placeholder={'Search By date'}
                format="dd-MM-yyyy"
                disabled={disabled}
                size="lg"
                value={fromDate}
                ranges={predefinedRanges}
                onChange={(newDate) => {
                    setFromDate(newDate)
                    if (newDate) {
                        let date1 = new Date(moment(new Date(newDate[0])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                        let date2 = new Date(moment(new Date(newDate[1])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                        captureDateValue(date1, date2)
                    }
                    else {
                        captureDateValue("", "")
                        setClear(true)
                    }
                }}
            />

        </Stack>
    )
}
export default DateRangePickerForDashBoard;
