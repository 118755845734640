import { Backdrop, Button, CircularProgress, Container, Stack, Avatar, TextField, Box, Typography, InputLabel, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Swal from "sweetalert2";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import account from '../_mock/account';

import styles from '../styles/profileView.module.scss';
import MenuIconList from "src/components/MenuIconList";
import ErrorMessages from "src/Core/ErrorMessages";

const EditViewProfile = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);
    const [editProfileErrorMessage, setEditProfileErrorMessages] = useState(false);


    const viewProfile = async () => {

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/profile`;
            let options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            setFirstName(responseData.data["first_name"]);
            setLastName(responseData.data["last_name"]);
            setMobile(responseData.data["mobile"]);
            setUserName(responseData.data["username"]);
            setEmail(responseData.data["email"]);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        viewProfile();
    }, [])

    const editProfile = async () => {

        try {
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/profile`;
            let options = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${signinSliceData.access_token}`,
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    mobile: mobile,
                    email: email,
                    username: userName,
                }),
            }
            let res = await fetch(url, options);
            let responseData = await res.json();

            if (responseData.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Profile Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    didClose: () => {
                        navigate("/dashboard/view-profile")
                    }
                })

            }
            if (responseData.success === false) {
                setEditProfileErrorMessages(responseData.error.issues)

            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false)
        }
    }


    const [searchParams, setSearchParams] = useSearchParams();

    const backToHome = () => {
        if (searchParams.get('from')) {
            navigate('/dashboard/videos');
        } else { navigate('/dashboard/view-profile') }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            editProfile();
        }
    };

    return (
        <>
            <div className={styles.navLinks}>
                <Button className={styles.backIcon} onClick={backToHome} ><KeyboardBackspaceIcon sx={{ color: "#000000" }} /> </Button>
                <div className={styles.menuIcon}>
                    <MenuIconList />
                </div>
            </div>
            <div style={{ height: " calc(100% - 40px)", display: "flex", alignItems: "center" }}>
                <div id={styles.profileEditPage}>
                    <Box className={styles.userDpEdit} sx={{
                        '& .MuiAvatar-root': {
                            width: "150px",
                            height: "150px",
                        },
                    }}>
                        <Avatar className={styles.avatar} src={account.photoURL} alt="photoURL" />
                        <img className={styles.editCamera} src="/assets/upload.svg" alt="camera" />
                    </Box>

                    <Stack spacing={2} className={styles.fieldsContainer} sx={{
                        '& .MuiFormControl-root': {
                            background: "transparent !important",
                            width: "100%",
                            marginTop: "0"
                        },
                        '& .MuiOutlinedInput-notchedOutline ': {
                            border: "0 !important"
                        }
                    }}>
                        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} md={6}>
                                <div className={styles.gridItem}>
                                    <Typography>First Name</Typography>
                                    <TextField
                                        sx={{ background: "tranparent !important", fontSize: "2rem", marginTop: "0 !important" }}
                                        name="First Name"
                                        type={"text"}
                                        required
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                        }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ErrorMessages errormessage={editProfileErrorMessage} pathname="first_name" />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={styles.gridItem}>
                                    <Typography>Last Name</Typography>
                                    <TextField
                                        sx={{ background: "#E8F0FE", fontSize: "2rem", marginTop: "0 !important" }}
                                        name="Last Name"
                                        type={"text"}
                                        required
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                        }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ErrorMessages errormessage={editProfileErrorMessage} pathname="last_name" />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={styles.gridItem}>
                                    <Typography>Email</Typography>
                                    <TextField
                                        sx={{ background: "#E8F0FE", fontSize: "2rem", marginTop: "0 !important" }}
                                        name="Email"
                                        type="email"
                                        required
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ErrorMessages errormessage={editProfileErrorMessage} pathname="email" />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={styles.gridItem}>
                                    <Typography>Mobile Number</Typography>
                                    <TextField
                                        sx={{ background: "#E8F0FE", fontSize: "2rem", marginTop: "0 !important" }}
                                        name="Phone"
                                        type="number"
                                        required
                                        value={mobile}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 10) {
                                                setMobile(e.target.value)
                                            }
                                        }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ErrorMessages errormessage={editProfileErrorMessage} pathname="mobile" />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={styles.gridItem}>
                                    <Typography>User Name</Typography>
                                    <TextField
                                        sx={{ background: "#E8F0FE", fontSize: "2rem", marginTop: "0 !important" }}
                                        name="User Name"
                                        type="text"
                                        required
                                        value={userName}
                                        onChange={(e) => {
                                            setUserName(e.target.value)
                                        }}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ErrorMessages errormessage={editProfileErrorMessage} pathname="username" />
                                </div>
                            </Grid>
                        </Grid>
                    </Stack>

                    <div className={styles.btnGroup}>
                        <Button className={styles.cancelBtn} type="submit" onClick={() => navigate('/dashboard/view-profile')} variant="text">Cancel</Button>
                        <Button className={styles.updateBtn} type="submit" onClick={editProfile} variant="contained">Update</Button>
                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </div>
        </>
    )
}
export default EditViewProfile;