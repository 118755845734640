import { LoadingButton } from "@mui/lab";
import { Backdrop, CircularProgress, IconButton, InputAdornment, Stack, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setData } from "src/StoreSlice/AuthSlice";
import styles from "src/styles/loginPage.module.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorMessages from "src/Core/ErrorMessages";
import { es } from "date-fns/locale";


const SignIn = () => {
    const signinSliceData = useSelector((state) => state.Signin.data);
    const userType = useSelector((state) => state.Signin.data.user_details);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [wrongUserName, setWrongUserName] = useState("");
    const [wrongPassword, setWrongPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessages] = useState(false);


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);




    useEffect(() => {

        if (signinSliceData.access_token) {

            if (userType.user_type == "ADMIN") {
                navigate("/dashboard/app");
            }
            else if (userType.user_type == "OPERATOR") {
                navigate("/dashboard/app");
            }
            else if (userType.user_type == "VIEWER") {
                navigate("/dashboard/watch-videos");
            }
        }
    })

    const signIn = async () => {

        try {
            setWrongUserName('');
            setWrongPassword('');
            setErrorMessages('');
            setLoading(true)
            let url = `${process.env.REACT_APP_LOCAL_API_URL}/users/signin`;
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accepts: "application/json",
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            }
            let res = await fetch(url, options);
            let responseData = await res.json();
            console.log("test", responseData);

            if (responseData.status === 200) {
                dispatch(setData(responseData.data));

                if (responseData?.data?.user_details?.user_type == "ADMIN") {
                    navigate("/dashboard/app");
                }
                else if (responseData?.data?.user_details?.user_type == "OPERATOR") {
                    navigate("/dashboard/app");
                }
                else if (responseData?.data?.user_details?.user_type == "VIEWER") {
                    navigate("/dashboard/watch-videos");
                }
            }
            else if (responseData.status === 401) {
                setWrongPassword(responseData.message)
            }
            else if (responseData.success == false) {
                if (responseData.error.issues.find((error) => error.path[0] == "password").message == "password must be atlest 6 characters long") {
                    setWrongPassword("Invalid Password")
                }
                else {
                    setErrorMessages(responseData.error.issues)
                }

            }
            // if (responseData.success === false) {
            //     setWrongPassword(responseData.message)
            //     setWrongUserName(responseData.message)

            // }

        } catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            signIn();
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <>
            <Grid container rowSpacing={{ xs: 3 }} className={styles.loginpageform}>
                <Grid item xs={12}>
                    <TextField
                        sx={{ marginTop: "1rem", width: "100%" }}
                        name="usermane"
                        label="Username"
                        type={"text"}
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <ErrorMessages errormessage={errorMessage} pathname="username" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: "100%" }}
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ErrorMessages errormessage={errorMessage} pathname="password" />
                </Grid>
                {wrongPassword ? <p style={{ color: "red" }}>{wrongPassword}</p> : ''}

                <Grid item xs={12}>
                    <LoadingButton className={styles.loginButton} fullWidth size="large" type="submit" variant="contained" onClick={signIn}>
                        Login
                    </LoadingButton>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>



        </>
    );
}
export default SignIn;