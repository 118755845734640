import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';


export default function ConfirmDeleteDialog({ row, onDelete, type = "video" }) {
    const [open, setOpen] = React.useState(true);



    const handleClose = (e, status = false) => {
        setOpen(false);
        onDelete(status)
    };

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Action Required !
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to delete  <i>{row.title}</i> {type}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={(e) => {
                        handleClose(e, true)
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}